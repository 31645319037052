<template>
  <div
    class="navbar-basket has-dropdown tw-items-center"
    title="Comparison basket"
    @click="toggle"
  >
    <div class="basket-toggler tw-h-full">
      <transition-group
        tag="span"
        name="fade"
        mode="out-in"
      >
        <span
          v-if="!isActive"
          key="other"
          class="icon is-large transition-in-place"
        >
          <div class="fa-stack fa-fw"><i class="fal fa-shopping-basket" /><span class="fa-layers-counter">{{ basketCount }}</span></div></span><span
          v-else
          key="thing"
          class="icon is-large transition-in-place"
        >
          <div class="fa-stack fa-fw"><i class="fal fa-times" /></div></span>
      </transition-group>
    </div>
  </div>
</template>

<script>
// Store actions
import { mapActions } from 'vuex'

export default {
  props: ['deviceNavOpen', 'isActive'],
  computed: {
    basket() {
      return this.$store.getters.getBasket
    },
    basketCount() {
      return this.$store.getters.basketCount
    },
    itemsInBasket() {
      return this.basketCount > 0
    }
  },
  methods: {
    ...mapActions([
      'closeBasket',
      'openModal',
      'clearBasket',
      'openBasket',
      'removeFromBasket'
    ]),
    remove(id) {
      this.removeFromBasket(id)
    },
    quoteComparison() {
      this.openModal('QuoteDetails')
      this.closeBasket()
    },
    clearBasketCloseMenu() {
      this.clearBasket()
      this.closeBasket()
    },
    toggle() {
      // Closes if slide nav if it is open
      // if (this.deviceNavOpen) {
      //   this.$emit('toggle-basket')
      // }
      this.$emit('toggle-basket')
    }
  }
}
</script>
