/**
 * Easier api calls
 */
let apiDomain = import.meta.env.VITE_API_DOMAIN
if (import.meta.env.SSR) {
  apiDomain = import.meta.env.VITE_SERVER_API_DOMAIN
}



export async function getPage(slug) {
  const response = await fetch(`${apiDomain}/api/pages/slug/${slug}`)
  const data = await response.json()
  return data
}
export async function getPageData(store, route) {
  return store.dispatch('fetchPage', route)
}
