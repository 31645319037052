import api from '@@/api/BlogApi'

// initial state
const state = {
  loading: false,
  loadedRecent: false,
  loadedPost: false,
  recentPosts: [],
  posts: [],
  currentPost: {},
  blogMeta: {
    title: '',
    description: '',
    url: '',
    image: '',
    card: '',
    ogType: ''
  }
}

const actions = {
  FETCH_RECENT_POSTS: ({ commit }) => {
    return api.fetchRecentPosts().then(posts => {
      commit('SET_RECENT_POSTS', posts)
      commit('SET_LOADING_RECENT', true)
    })
  },
  FETCH_POSTS: ({ commit }) => {
    return api.fetchPosts().then(posts => {
      commit('SET_POSTS', posts)
    })
  },
  BLOG_ROUTE_CHANGED: ({ commit }) => {
    // Reset loadedPost to false
    commit('SET_LOADED_POST', false)
  },
  // FETCH_SINGLE_POST: ({ commit, dispatch, state }, slug) => {
  //   return api.fetchSinglePost(slug).then(post => {
  //     commit('SET_BLOG_META', {
  //       data: { meta: api.getBlogMeta(post) },
  //       slug: slug
  //     })
  //     commit('SET_SINGLE_POST', post)
  //   })
  // }
}

// mutations
const mutations = {
  SET_RECENT_POSTS: (state, posts) => {
    state.recentPosts = posts
  },
  SET_POSTS: (state, posts) => {
    state.posts = posts
  },
  SET_SINGLE_POST: (state, post) => {
    state.currentPost = post
  },
  SET_LOADING_RECENT: (state, value) => {
    state.loadedRecent = value
  },
  SET_LOADED_POST: (state, value) => {
    state.loadedPost = value
  },
  SET_BLOG_META: (state, content) => {
    const { slug, data } = content
    const pageMeta = {
      title: data.meta.title || '',
      description: data.meta.description || '',
      card: data.meta.card || '',
      url: `${process.SITE_DOMAIN}${data.meta.url}/posts/${slug}`,
      image: data.meta.image || '',
      ogType: data.meta.ogType || ''
    }
    state.blogMeta[slug] = pageMeta
  }
}

const getters = {
  isBlogLoaded: state => state.loading,
  isRecentLoaded: state => state.loadedRecent,
  loadedPost: state => state.loadedPost,
  currentPost: state => state.currentPost,
  getBlogMetaInfo: (state, getters, { route }) => {
    const slug = route.params.slug
    const tag = route.params.tag
    const category = route.params.category

    if (!slug) {
      if (tag) {
        return {
          title: `Posts tagged with: ${tag} - Copytech`,
          description: `A list of posts with the ${tag} tag.`,
          card: 'summary',
          url: `/blog/tags/${tag}`,
          image: 'https://assets.copytechgroup.co.uk/assets/copytech-logo.png',
          ogType: 'website'
        }
      }
      if (category) {
        // Category
        return {
          title: `Posts tagged with: ${category} - Copytech`,
          description: `A list of posts with the ${category} category.`,
          card: 'summary',
          url: `/blog/categories/${category}`,
          image: 'https://assets.copytechgroup.co.uk/assets/copytech-logo.png',
          ogType: 'website'
        }
      } else {
        return {
          card: 'summary',
          description: 'Useful and insightful articles about printers and the office environment.',
          image: 'https://assets.copytechgroup.co.uk/assets/copytech-logo.png',
          ogType: 'website',
          url: '/blog',
          title: 'The Copytech blog'
        }
      }
    }
    return state.blogMeta[slug]
  },
  posts: state => state.posts,
  recentBlogPosts: state => state.recentPosts
}

export default { state, actions, mutations, getters }
