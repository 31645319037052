const baseUrl = 'https://assets.copytechgroup.co.uk'
const RE = /\s/gi // Regex for Whitespace

// get nearest parent element matching selector
export function closest (el, selector) {
  var matchesSelector =
    el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector

  while (el) {
    if (matchesSelector.call(el, selector)) {
      break
    }
    el = el.parentElement
  }
  return el
}

export function preLoadImages (images, brand, model, size) {
  var sizes = {
    thumb: 'x135',
    small: 'x270',
    medium: 'x540',
    large: 'x810'
  }

  let cache = images.map(i => {
    let img = new Image()
    img.src = `${baseUrl}/${sizes[size]}/${brand}/${model}/${i.filename}`
      .replace(RE, '-')
      .toLowerCase()
    return img
  })
  return cache
}
