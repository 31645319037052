<template>
  <div class="basket">
    <div class="pad-single">
      <p class="title is-4 is-font-regular u-no-wrap">
        Quote basket
      </p>
      <p class="subtitle is-6 has-colour-primary">
        Get an instant quote on up to five printers at a time.
      </p>
      <ul
        v-if="itemsInBasket"
        class="block u-no-wrap"
      >
        <li
          v-for="item, i in basket"
          :key="i"
          class="basket-item"
        >
          <div class="level is-mobile">
            <div class="level-left">
              <div class="level-item">
                <div class="is-block">
                  <strong class="is-block">{{ item.model }}</strong><span
                    class="is-block"
                  >{{ item.brand }}</span>
                </div>
              </div>
            </div>
            <div class="level-right">
              <span class="level-item"><span
                class="icon match-line"
                @click="remove(item.modelId)"
              ><i class="fal fa-minus-circle" /></span></span>
            </div>
          </div>
        </li>
      </ul>
      <div class="field has-addons">
        <button
          class="button is-action is-outlined is-fullwidth quote-all-printers-button tw-transition-all tw-duration-300 tw-py-3"
          :disabled="!itemsInBasket"
          @click="quoteComparison"
        >
          <span class="icon is-small"><i class="fal fa-bolt" /></span><span>Instant
            quote</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// Store actions
import { mapActions } from 'vuex'

export default {
  props: ['deviceNavOpen'],
  computed: {
    basket() {
      return this.$store.getters.getBasket
    },
    basketCount() {
      return this.$store.getters.basketCount
    },
    itemsInBasket() {
      return this.basketCount > 0
    }
  },
  methods: {
    // openBasket () {
    //   this.toggleBasketOpen()
    // },
    ...mapActions(['closeBasket', 'openModal', 'clearBasket', 'openBasket', 'removeFromBasket']),
    remove(id) {
      this.removeFromBasket(id)
    },
    quoteComparison() {
      this.openModal('QuoteDetails')
      this.closeBasket()
    },
    clearBasketCloseMenu() {
      this.clearBasket()
      this.closeBasket()
    },
    closeNav() {
      // Closes if slide nav if it is open
      if (this.deviceNavOpen) {
        this.$emit('toggle-nav')
      }
    }
  }
}
</script>
