<template>
  <div>
    <!-- "Hub links" -->
    <CopytechHubLinks />

    <!-- Main navigation -->
    <NavBar />

    <!-- Router/Page content view -->
    <router-view :key="$route.fullPath" />

    <!-- Site Footer -->
    <SiteFooter />

    <!-- Modal component -->
    <transition name="fade">
      <Modal v-if="openModal" />
    </transition>

    <!-- Cookie consent -->
    <transition name="fade">
      <CookieConsent />
    </transition>
  </div>
</template>

<script>
import CopytechHubLinks from './components/CopytechHubLinks.vue'
import NavBar from './components/NavBar.vue'
import SiteFooter from './components/SiteFooter.vue'
import Modal from './components/Modal.vue'
import { getBrandModelId } from './helpers/product'
import CookieConsent from './components/CookieConsent.vue'
// import ProductPageBanner from './components/ProductPageBanner.vue'
import titleMixin from './components/mixins/meta/title-mixin'

import { usePagesStore } from './store/modules/pages'
import { useProductsStore } from './store/modules/products'

import { getUTMFromQuery } from './helpers/util'

// async function fetchPage(store, route) {
//   return store.dispatch('fetchPage', route)
// }

async function fetchProduct(route) {
  const brand = route.params.brand
  const model = route.params.model
  const type = route.params.type
  const productStore = useProductsStore()
  const modelId = getBrandModelId(brand, model)

  const request = {
    brand,
    model,
    type,
    modelId
  }

  if (route.meta.fetchScanners) {
    return productStore.FETCH_SCANNER(request, route)
  }

  return productStore.FETCH_PRINTER(request, route)
}


async function fetchProductList(route) {
  const type = route.params.type
  const productStore = useProductsStore()
  if (route.meta.fetchScanners) {
    // Scanner list page
    switch (type) {
      case 'all':
        await productStore.getAllScanners()
        break
      case 'personal':
        await productStore.getPersonalScanners()
        break
      case 'desktop-workgroup':
        await productStore.getDesktopWorkgroupScanners()
        break
      case 'departmental':
        await productStore.getDepartmentalScanners()
        break
      case 'production':
        await productStore.getProductionScanners()
        break

      default:
        await productStore.getAllScanners()
    }
  } else {
    // Printer list page
    if (type === 'multifunctionals') {
      await productStore.getMultifunctionalPrinters()
    } else if (type === 'production') {
      await productStore.getProductionPrinters()
    } else if (type === 'plotter') {
      await productStore.getPlotterPrinters()
    } else {
      await productStore.getOfficePrinters()
    }
  }
}

export default {
  name: 'App',
  components: {
    CopytechHubLinks,
    CookieConsent,
    NavBar,
    SiteFooter,
    Modal
  },
  mixins: [titleMixin],
  computed: {
    openModal() {
      return this.$store.getters.getModalStatus
    }
  },
  async serverPrefetch() {
    try {
      if (this.$route.meta.fetchPage) {
        await this.fetchPage()
      }

      if (this.$route.meta.fetchProduct) {
        await this.fetchProduct()
      } else if (this.$route.meta.fetchProductList) {
        await this.fetchProductList()
      }
    } catch (err) {
      // console.log(err);
      throw { statusCode: 404, message: 'Page not found' }
    }
  },
  mounted() {
    // Loads printer baskets from storage if it exists
    this.$store.dispatch('loadBasketFromStorage')

    // Store UTM parameters in a cookie
    const query = this.$route.query

    const utm = getUTMFromQuery(query)

    if (utm) {
      // Add utm_capture_date to the object
      utm.utm_capture_date = new Date().toISOString()
      // Store in local storage
      localStorage.setItem('utm_data', JSON.stringify(utm))
    }

  },
  methods: {
    async fetchProductList() {
      await fetchProductList(this.$route, this.type)
    },
    async fetchPage() {
      const store = usePagesStore()
      await store.fetchPage(this.$route)
    },
    async fetchProduct() {
      await fetchProduct(this.$route)
    }
  },
}
</script>
