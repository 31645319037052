<template>
  <router-link
    :key="link.url"
    class="tw-flex tw-transition-all hover:tw-bg-[#f4f4fa] hover:tw-text-primary tw-text-base md:tw-text-lg tw-py-2 tw-px-6 tw-pr-4 md:tw-px-2 tw-items-center dropdown-item-icon flex-mobile has-space-between"
    :to="link.url"
    exact-active-class="has-text-primary tw-bg-zinc-100"
    @click="handleNavigationClick"
  >
    <!-- Link text -->
    <span>{{ link.text }}</span>

    <!-- Link icons -->
    <!-- CGS printer icons -->
    <figure
      v-if="iconType === 'cgs-printer-icon'"
      class="image is-24x24"
    >
      <img
        v-if="link.icon === 'full-colour'"
        alt="Colour printer icon"
        src="https://assets.copytechgroup.co.uk/images/colour-wheel-medium.png"
      >
      <img
        v-else
        alt="Mono printer icon"
        src="https://assets.copytechgroup.co.uk/images/mono-wheel-medium.png"
      >
    </figure>

    <!-- Fontawesome icons -->
    <span
      v-else
      class="icon match-line"
    ><i
      class="fal"
      :class="link.icon"
    /></span>
  </router-link>
</template>
<script>
// Refactor our dropdown items in to this file.
// They are links with text and icons
export default {
  name: 'NavDropdownItem',
  props: {
    link: {
      type: Object,
      required: true
    }
  },
  emits: ['navigate'],
  computed: {
    iconType() {
      // Choose the icon type based on the link object
      if (this.link.icon === 'full-colour') {
        return 'cgs-printer-icon'
      }
      if (this.link.icon === 'mono') {
        return 'cgs-printer-icon'
      }
      return 'fa-icon'
    }
  },
  methods: {
    handleNavigationClick(e) {
      this.$emit('navigate', e)
    }
  }
}
</script>
