<template>
  <nav class="navbar-menu global-nav is-layer-back has-background-primary">
    <div class="navbar-start">
      <a
        class="tw-px-3 tw-py-1 tw-flex tw-flex-grow-0 tw-flex-shrink-0 tw-align-items-center has-background-transparent has-colour-white tw-text-base tw-lowercase smallcaps tw-leading-4"
        href="https://copytechgroup.co.uk/"
        title="Printers and printer servicing for businesses"
      >
        Copytech Group
      </a>
    </div>
    <div class="navbar-end">
      <a
        class="tw-px-3 tw-py-1 tw-flex tw-flex-grow-0 tw-flex-shrink-0 tw-align-items-center has-background-transparent has-colour-white tw-text-base tw-lowercase smallcaps tw-leading-4"
        :href="'tel:' + cleanTel(phoneDefault)"
      >
        <span class="icon tw-h-4"><i class="fal fa-phone" /></span><span>{{ phoneDefault }}</span></a>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'CopytechHubLinks',
  data() {
    return {
      phoneDefault: '0207 237 4545',
    }
  },
  mounted() {
    const href = window.location.href
    const el = this.$el.querySelector(`a[href^="${href}"]`)
    if (el) {
      el.style.backgroundColor = 'rgba(0, 0, 0, .3)'
    }
  },
  methods: {
    cleanTel(val) {
      const re = /\s/gi
      return val.replace(re, '')
    }
  }
}
</script>
