<template>
  <div class="confirmation pad-single">
    <h3 class="title">
      What next?
    </h3>
    <p class="is-copy paragraph">
      You don't have to do anything, your quote is
      already being processed and will soon be in your inbox.
      Inside you will find everything you need to proceed.
      If you haven't received your quote after a few minutes,
      check your junk folder, just in case it has ended up there.
    </p>
    <p><strong>If you have any issues call us on</strong></p>
    <p class="title is-4 has-colour-primary has-font-weight-bold">
      0207 237 4545
    </p>
    <button
      class="button is-success is-fullwidth"
      @click="close"
    >
      Ok!
    </button>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {}
  },
  methods: {
    ...mapActions(['closeModal', 'switchModal']),
    close () {
      this.$emit('close')
      this.switchModal('')
    }
  }
}
</script>
