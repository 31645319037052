<template>
  <div class="quote-details pad-single">
    <p class="title">
      Instant quote
    </p>
    <p class="subtitle">
      Fill in your details and we'll send you your quote.
    </p>
    <small class="is-block tw-mb-3"><span class="icon is-small has-colour-primary"><i class="fal fa-bolt" /></span><span class="has-colour-primary">Average delivery time 2 minutes</span></small>
    <!-- <data-form :horizontal="false" :fields="formData" button-colour="is-success" :has-captcha="false" submit-text="Get your instant quote" @submit="submit"><small class="is-block paragraph" slot="footer"><span class="icon is-small has-colour-success"><i class="fal fa-user-secret"></i></span><span class="has-colour-success">Privacy guaranteed. Your info will not be shared.</span></small></data-form> -->
    <static-form
      button-colour="is-success"
      submit-text="Get your instant quote"
      @submit-form="submitForm"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { updateDataLayer } from "../../helpers/util";
import { fbLeadEvent } from "../../helpers/util";
// import DataForm from '../form/Form.vue'
import StaticForm from "../form/StaticForm.vue";
import { getUTMFromLocalStorage } from "../../helpers/util";

export default {
  name: "DetailsForm",
  components: {
    // DataForm,
    StaticForm,
  },
  data() {
    return {
      formData: [
        {
          // label: 'Name',
          name: "name",
          icon: "fa-user",
          validations: { presence: { allowEmpty: false } },
          value: "",
          placeholder: "Name",
          type: "text",
          autocomplete: "name",
          required: true,
        },
        {
          // label: 'Email',
          name: "email",
          icon: "fa-envelope",
          validations: { presence: { allowEmpty: false }, email: true },
          value: "",
          placeholder: "Email",
          type: "email",
          autocomplete: "email",
          required: true,
        },
        // {
        //   label: 'Company',
        //   name: 'company',
        //   icon: 'building',
        //   validations: { presence: { allowEmpty: false } },
        //   value: '',
        //   placeholder: 'Company name',
        //   type: 'text'
        // }
        {
          // label: 'Phone number',
          name: "phone",
          icon: "fa-phone",
          validations: {
            presence: { allowEmpty: false },
            format: {
              pattern: "phoneNumber",
              message: "is not a valid phone number",
            },
          },
          value: "",
          placeholder: "Phone number",
          type: "text",
          required: true,
        },
        {
          // label: 'Postcode',
          name: "postCode",
          icon: "fa-map-marker",
          validations: {
            presence: { allowEmpty: false },
            format: { pattern: "postCode", message: "is not a valid postcode" },
          },
          value: "",
          placeholder: "Postcode",
          type: "text",
          autocomplete: "postal-code",
          required: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      basket: "getBasket",
      isInstant: "isInstant",
      instant: "getInstantItem",
    }),
    products() {
      // Only send modelId of items in basket
      return this.isInstant ? [this.instant] : Object.keys(this.basket);
    },
  },
  methods: {
    ...mapActions([
      "closeModal",
      "switchModal",
      "clearBasket",
      "storeQuoteData",
    ]),
    submitForm(fields) {
      // Convert to key value pairs before sending
      const data = {
        contactDetails: {},
        recaptcha: this.$store.getters.recaptchaData,
        queryContext: "quote",
      };
      Object.keys(fields).forEach((key) => {
        data.contactDetails[key] = fields[key];
      });
      // fields.forEach(field => {
      //   data.contactDetails[field.name] = field.value
      // })

      // Api expects printers
      data.printers = this.products;

      // Get UTM data from local storage
      const utmData = getUTMFromLocalStorage();

      if (utmData) {
        data.utm = utmData;
      }

      // this.$api.Form.getQuoteData(data).then(response => {
      //   // reset basket
      //   // this.clearBasket()
      //   // this.switchModal('Confirmation')
      //   this.storeQuoteData(response)
      // })

      this.$api.Form.submitUniForm("quote", data).then(() => {
        // Send event to GA before showing confirmation
        updateDataLayer("event", "quote_request_submitted");
        // Send lead event to fb
        fbLeadEvent();
        // reset basket
        this.clearBasket();
        this.switchModal("Confirmation");
      });
    },
  },
};
</script>
