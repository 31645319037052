let apiDomain = import.meta.env.VITE_API_DOMAIN
if (import.meta.env.SSR) {
  apiDomain = import.meta.env.VITE_SERVER_API_DOMAIN
}

export default {
  fetchRecentPosts() {
    const url = `${apiDomain}/api/blog/recent`

    return fetch(url).then((response) => {
      const body = response.json()
      return body
    })
  },
  getBlogMeta: function (data) {
    // Blog tags, categories and posts
    const meta = {
      title: data.title,
      description: data.snippet,
      card: 'summary',
      url: '/blog',
      image: data.headerImage,
      ogType: 'article'
    }
    return meta
  }
}
