import { set } from 'lodash-es'

// All money values are in pence

// initial state
function initialState() {
  return {
    purchaseMethod: '',
    equipmentIsInsured: '',
    equipmentPurchasePeriodYears: '',
    howIsEquipmentSupported: '',
    tonerDeliveryPaidFor: '',
    networkSupportPaidFor: '',
    payOtherCharges: '',
    equipmentPurchasePrice: {
      periodInMonths: 3,
      value: '0'
    },
    leasePricePerPeriod: {
      periodInMonths: 3,
      value: '0'
    },
    cpcPricePerPeriod: {
      periodInMonths: 3,
      value: '0'
    },
    warrantyPrice: {
      periodInMonths: 3,
      value: '0'
    },
    spendPricePerPeriod: {
      periodInMonths: 3,
      value: '0'
    },
    otherChargesPricePerPeriod: [
      {
        name: '',
        value: '0',
        periodInMonths: 3
      }
    ],
    blackAndWhiteVolume: {
      periodInMonths: 3,
      value: 0
    },
    colourVolume: {
      periodInMonths: 3,
      value: 0
    },
    // Price per page in pence
    copytechPricePerPage: {
      mono: 0.39,
      colour: 3.3
    },
    // Track completed steps (push), so that we can retrace
    completedSteps: []
  }
}

const state = initialState

const actions = {
  setSearchModel: ({ commit }, data) => {
    commit('SET_SEARCH_MODEL', data)
  },
  setInputMethod: ({ commit }, data) => {
    commit('SET_INPUT_METHOD', data)
  },
  // Will take +1 or -1 to mvoe forward / back
  changeStep: ({ commit }, distance) => {
    commit('CHANGE_STEP', distance)
  },
  addCompletedStep: ({ commit }, step) => {
    commit('ADD_COMPLETED_STEP', step)
  },
  removeCompletedStep: ({ commit }, step) => {
    return commit('REMOVE_COMPLETED_STEP', step)
  },
  addBill: ({ commit }) => {
    let vals = {
      colourPages: 0,
      monoPages: 0,
      pages: 0,
      cost: 0,
      colourRatio: 30
    }
    commit('ADD_BILL', vals)
  }
}

const mutations = {
  RESET(state) {
    const initial = initialState()

    Object.keys(initial).forEach(key => {
      state[key] = initial[key]
    })
  },
  SET_VALUE(state, data) {
    set(state, data.target, data.value)
  },
  SET_DATA_VALUE(state, data) {
    state[data.target] = data.value
  },
  PUSH_DATA_VALUE(state, data) {
    const found = state[data.target].findIndex(o => {
      return o.name === data.value.name
    })
    // If the item already exists, just update its value
    // Else just add normally
    if (found >= 0) {
      state[data.target][found].value = data.value.value
    } else {
      state[data.target].push(data.value)
    }
  },
  ADD_BILL(state, vals) {
    state.bills.push(vals)
  },
  ADD_COMPLETED_STEP(state, step) {
    state.completedSteps.push(step)
  },
  REMOVE_COMPLETED_STEP(state, step) {
    return state.completedSteps.pop(step)
  }
}

const getters = {
  // getCurrentStep: state => state.currentStep,
  // getBills: state => state.bills,
  // getInputMethod: state => state.inputMethod,
  // getCurrentData: state => state.currentData
}

export default { state, actions, mutations, getters }
