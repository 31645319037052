function install(app) {
  function hasClass(element, cls) {
    return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1
  }

  app.config.globalProperties.$hasClass = hasClass

  /**
   * Search element ancestors for searchClass - optionally stop at stopClass
   * @param  {string} searchClass The class to search for
   * @param  {string} stopClass   The class to stop at if found
   */
  app.config.globalProperties.$searchAncestors = function (element, searchClass, operation, stopClass) {
    let el = element
    while (el.parentNode) {
      // Perform operation on elements found
      if (hasClass(el, searchClass)) {
        operation(el)
      }

      // Stop loop if we find stop class
      if (hasClass(el, stopClass)) {
        break
      }

      // Change el reference to the parent node to move up tree
      el = el.parentNode
    }
  }

  /**
   * Search element decendants for searchClass
   * @param  {string} searchClass The class to search for
   */
  app.config.globalProperties.$searchChildren = function (element, searchClass, operation) {
    const elements = element.querySelectorAll(searchClass)
    elements.forEach(el => {
      operation(el)
    })
  }
}
export default install
