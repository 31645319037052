import { createSSRApp } from "vue";
import App from "./app.vue";
import { storeConfig } from "./store";
import { createStore } from "vuex";
import { createRouter } from "./router";
import { createPinia } from "pinia";
import { VueHeadMixin, createHead } from "@unhead/vue";

import UtilPlugin from "./plugins/util";
import Api from "./api";

// Test for if we are running in the browser
// const inBrowser = typeof window !== 'undefined'

// In server use localhost as domain, to avoid going across internet to fetch data
let apiDomain;
if (typeof window === "undefined") {
  apiDomain = import.meta.env.VITE_SERVER_API_DOMAIN;
} else {
  apiDomain = import.meta.env.VITE_API_DOMAIN;
}

// Expose a factory function that creates a fresh set of store, router,
// app instances on each call (which is called for each SSR request)
export function createApp() {
  const app = createSSRApp(App);
  const head = createHead();
  const router = createRouter();
  const pinia = createPinia();
  const store = createStore(storeConfig);

  app.use(head);
  app.mixin(VueHeadMixin);
  app.use(pinia);
  app.use(store);
  app.use(router);

  // Set API domain
  app.config.globalProperties.$apiDomain = apiDomain;
  app.config.globalProperties.apiDomain = apiDomain;

  app.use(Api);
  app.use(UtilPlugin);

  // expose the app, the router and the store.
  // note we are not mounting the app here, since bootstrapping will be
  // different depending on whether we are in a browser or on the server.
  return { app, router, store, pinia, head };
}
