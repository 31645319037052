<template>
  <!-- Nav bar item -->
  <div
    class="tw-flex"
    @click="itemClicked"
    @mouseenter="itemHoverOn"
    @mouseleave="itemHoverOff"
  >
    <!-- Link / Heading -->
    <a class="tw-hidden md:tw-flex navbar-link">{{ heading }}</a>

    <!-- Dropdown content -->
    <NavDropdown
      :content="content"
      :class="{ 'md:tw-hidden': isClosed }"
      @navigate="closeDropdown"
    />
  </div>
</template>
<script>
import NavDropdown from './NavDropdown.vue'
import { debounce } from 'lodash-es'

const leaveDropdown = debounce((cb) => {
  cb()
}, 40)

export default {
  name: 'MegaMenu',
  components: {
    NavDropdown
  },
  props: {
    heading: {
      type: String,
      required: true
    },
    content: {
      type: Object,
      required: true
    }
  },
  emits: ['navigate'],
  data() {
    return {
      active: false,
      isClosed: true,
      openItems: '',
    }
  },
  methods: {
    // Handle clicking of menu sub-item
    itemClicked(e) {
      // Only needed on device menus, as larger screens show all options at once
      if (this.deviceNav) {
        // Add active class to element and push onto array tacking elements
        e.currentTarget.classList.add('is-active')
        // this.openItems.push(e.currentTarget)
        this.$emit('toggle-back', true)
      }

      // Toggle dropdown
      this.isClosed = !this.isClosed
    },
    closeDropdown(e) {
      this.active = false

      this.$emit('navigate', e)

      this.openItems.classList.remove('is-active')
    },
    itemHoverOn(e) {
      // Start capturing mouse move events
      if (e.currentTarget === this.openItems) {
        leaveDropdown.cancel()
      } else {
        e.target.classList.add('is-active')
        this.openItems = e.currentTarget
        // this.openItems = e.currentTarget
        this.active = true
        this.isClosed = false
      }
    },
    itemHoverOff(e) {
      // When moving offhover item, wait a small amount of time before removing class, in case
      // we are just moving to dropdown
      leaveDropdown(() => {
        e.target.classList.remove('is-active')
        this.openItems = ''
        this.active = false
        this.isClosed = true
      })
    },
  }
}
</script>