/**
 * Easier api calls
 */
let apiDomain = import.meta.env.VITE_API_DOMAIN
if (import.meta.env.SSR) {
  apiDomain = import.meta.env.VITE_SERVER_API_DOMAIN
}

// Printer api calls
export async function getMultifunctionalPrinters() {
  const response = await fetch(`${apiDomain}/api/printers/multifunctionals`)
  return response.json()
}
export async function getOfficePrinters() {
  const response = await fetch(`${apiDomain}/api/printers/officeprinters`)
  return response.json()
}
export async function getProductionPrinters() {
  const response = await fetch(`${apiDomain}/api/printers/productionprinters`)
  return response.json()
}
export async function getPlotterPrinters() {
  const response = await fetch(`${apiDomain}/api/printers/plotterprinters`)
  return response.json()
}
export async function getAllPrinters() {
  const response = await fetch(`${apiDomain}/api/printers/all`)
  return response.json()
}

// Scanner api calls
export async function getPersonalScanners() {
  const response = await fetch(`${apiDomain}/api/scanners/personal`)
  return response.json()
}
export async function getDepartmentalScanners() {
  const response = await fetch(`${apiDomain}/api/scanners/departmental`)
  return response.json()
}
export async function getProductionScanners() {
  const response = await fetch(`${apiDomain}/api/scanners/production`)
  return response.json()
}
export async function getDesktopWorkgroupScanners() {
  const response = await fetch(`${apiDomain}/api/scanners/desktop-workgroup`)
  return response.json()
}
export async function getAllScanners() {
  const response = await fetch(`${apiDomain}/api/scanners/all`)
  return response.json()
}

export async function getFeaturedPrinters() {
  const response = await fetch(`${apiDomain}/api/featuredprinters/`)
  return response.json()
}

export async function getSingleProduct(modelId) {
  const response = await fetch(`${apiDomain}/api/printers/getsingleprinter/${modelId}`)
  return response.json()

}

export async function getSingleScanner(modelId) {
  const response = await fetch(`${apiDomain}/api/scanners/getsinglescanner/${modelId}`)
  return response.json()
}

export async function getSimiliarPrinters(modelId) {
  const response = await fetch(`${apiDomain}/api/printers/similiar?id=` + modelId)
  return response.json()
}