/**
 * Easier api calls
 */
// console.log(import.meta.env)
const domain = `${import.meta.env.VITE_API_DOMAIN}`

const url = `${domain}/api/forms/getquote`
const enqUrl = `${domain}/api/enquiries/send`
const reportUrl = `${domain}/api/report`
const quoteUrl = `${domain}/api/quote`
const urls = {
  enquiry: ' https://nkt2mdk4gi.execute-api.eu-west-2.amazonaws.com/v1/enquiry/general',
  quote: quoteUrl,
  report: reportUrl
}

async function postData(url = '', data = {}, headers = {}) {
  const response = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(data)
  })

  return response.json()
}

export default {
  sendData(data) {
    return postData(url, data).then((body) => {
      return body
    })
  },
  sendEnquiry(data) {
    return postData(enqUrl, data).then((body) => {
      return body
    })
  },
  sendReport(data) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    return postData(reportUrl, data, headers).then((body) => {
      return body
    })
  },
  verifyCaptcha(response) {
    return postData(`${domain}/api/captcha`, { params: { response: response } }).then((body) => {
      return body
    })

  },
  verifyInvisiCaptcha(response) {
    return postData(`${domain}/api/invisicaptcha`, { params: { response: response } })
      .then((body) => {
        return body
      })
  },
  verifyCaptchaV3(response) {
    return postData(`${domain}/api/captcha`, { params: { response: response } })
      .then((body) => {
        return body
      })

  },
  submitUniForm(endpoint, data) {
    const url = urls[endpoint]
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-api-key': 'C1idIcmVAG5HPNnsqIcpx8Ct0qmBjAE3xkzy1K9a'
    }

    return postData(url, data, headers).then((body) => {
      return body
    })
  },
  submitForm(endpoint, data) {
    const url = urls[endpoint]

    return postData(url, data, headers).then((body) => {
      return body
    })

  }
}
