<template>
  <div class="modal-card instant-basket-items">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ heading }}
      </p>
      <button
        class="delete"
        @click="closeModal"
      />
    </header>
    <section class="modal-card-body">
      <div class="block">
        <p class="instant-basket-items__text">
          You are checking out an instant quote but we detected you have items in your basket. Do you also want a quote on these items?
        </p>
      </div>
      <ul class="instant-basket-items__list">
        <li
          v-for="item, i in basket"
          :key="i"
          class="instant-basket-items__list__item"
        >
          {{ item.model }}
        </li>
      </ul>
      <button
        class="button"
        @click.prevent="continueQuote"
      >
        No
      </button>
      <button
        class="button"
        @click.prevent="combineItemsAndContinue"
      >
        Yes
      </button>
    </section>
  </div>
</template>
<script>
// Components
import Icon from '../Icon.vue'

// Vuex
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    Icon
  },
  data() {
    return {
      quoteType: 'single'
    }
  },
  computed: mapGetters({
    basket: 'getBasket',
    isInstant: 'isInstant',
    instant: 'getInstantItem'
  }),
  methods: {
    ...mapActions(['closeModal', 'switchModal', 'setInstant', 'addToBasket']),
    submitForm() {
      // The default quote type is single, if quoteType changed set to false
      if (this.quoteType !== 'single') {
        this.toggleIsSingle()
      }
      this.switchModal('QuoteDetails')
    },
    continueQuote() {
      this.switchModal('QuoteDetails')
    },
    combineItemsAndContinue() {
      // Add object in basket.instant to basket.basket and continue
      const modelId = Object.keys(this.instant)[0]
      // Check if item is in basket alreadyt
      if (!this.basket[modelId]) {
        // Get instant as object
        const item = this.instant[modelId]
        // Add item to basket if there aren't more than 5
        if (Object.keys(this.basket).length < 6) {
          this.addToBasket(item)
        }
      }
      // Clear instant
      this.setInstant(false)
      this.continueQuote()
    }
  }
}
</script>
