<script lang="ts">
// import { get } from 'lodash-es'
// import { set } from 'lodash-es'
// import formats from './validation-formats'
// import validate from 'validate.js'

export default {
  data() {
    return {
      complete: false,
      triggerValidation: false,
      name: '',
      email: '',
      phone: '',
      postCode: '',
      fields: [
        {
          // label: 'Name',
          name: 'name',
          icon: 'fa-user',
          validations: { presence: { allowEmpty: false } },
          valid: false,
          value: '',
          placeholder: 'Name',
          type: 'text',
          autocomplete: 'name',
          required: true
        },
        {
          // label: 'Email',
          name: 'email',
          icon: 'fa-envelope',
          validations: { presence: { allowEmpty: false }, email: true },
          valid: false,
          value: '',
          placeholder: 'Email',
          type: 'email',
          autocomplete: 'email',
          required: true
        },
        {
          // label: 'Phone number',
          name: 'phone',
          icon: 'fa-phone',
          validations: { presence: { allowEmpty: false }, format: { pattern: 'phoneNumber', message: 'is not a valid phone number' } },
          valid: false,
          value: '',
          placeholder: 'Phone number',
          type: 'text',
          required: true
        },
        {
          // label: 'Postcode',
          name: 'postCode',
          icon: 'fa-map-marker',
          validations: { presence: { allowEmpty: false }, format: { pattern: 'postCode', message: 'is not a valid postcode' } },
          valid: false,
          value: '',
          placeholder: 'Postcode',
          type: 'text',
          autocomplete: 'postal-code',
          required: true
        }
      ]
    }
  },
  methods: {
    // validate() {
    //   if (this.field.validations) {
    //     const validation = validate.single(
    //       this.field.value,
    //       this.field.validations
    //     )
    //     if (validation) {
    //       this.valid = false
    //       // Set field valid status to false to prevent submission after the removal of a valid value
    //       this.field.valid = false
    //       this.validationMessage = validation
    //     } else {
    //       this.valid = true
    //       // Add valid flag to field
    //       this.field.valid = true
    //       // Emit completed event so we can check to see if form is valid after changes
    //       this.$emit('completed')
    //       // If auto is set, emit auto event so we can update/submit automatically
    //       if (this.auto) {
    //         this.$emit('auto-update')
    //       }
    //     }
    //   }
    // },
    submitForm() {
      const fields = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        postCode: this.postCode,
      }
      // Validate all inputs
      // this.triggerValidation = true
      const form = this.$refs['request-quote-form'] as HTMLFormElement

      const isValid = form.checkValidity()


      if (isValid) {
        this.$emit('submitForm', fields)
      }

      // Emit submit event and pass fields with it
      // const complete = this.fields.every(field => field.valid === true)
      // if (complete) {
      //   this.$emit('submit', fields)
      // }
    }
  },
  mounted() {
    // Replace formats with matching regexes
    // Set validation formats
    // const format = get(this.field, 'validations.format.pattern')
    // if (format) {
    //   set(this.field, 'validations.format.pattern', formats[format])
    // }
  }
}
</script>

<template>
  <form @submit.prevent="submitForm" ref="request-quote-form">

    <div class="field">
      <div class="">
        <div class="field">
          <div class="has-icons-left control">
            <input v-model="name" autocomplete="name" class="input tw-w-full" placeholder="Name" required="true"
              type="text" />
            <span class="icon is-large is-left">
              <i class="fa-user fal"></i>
            </span>

          </div>
        </div>
      </div>
    </div>

    <div class="field">
      <div class="">
        <div class="field">
          <div class="has-icons-left control">
            <input v-model="email" autocomplete="email" class="input tw-w-full" placeholder="Email" required="true"
              type="email" />
            <span class="icon is-large is-left">
              <i class="fa-envelope fal"></i>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="field">
      <div class="">
        <div class="field">
          <div class="has-icons-left control">
            <input v-model="phone" autocomplete="off" class="input tw-w-full" placeholder="Phone number"
              pattern="^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$"
              required="true" type="tel" />
            <span class="icon is-large is-left">
              <i class="fa-phone fal"></i>
            </span>
          </div>
        </div>

      </div>
    </div>

    <div class="field">
      <div class="">
        <div class="field">
          <div class="has-icons-left control">
            <input v-model="postCode" autocomplete="postal-code" class="input tw-w-full" placeholder="Postcode"
              required="true" type="text"
              pattern="([A-PR-UWYZa-pr-uwyz]([A-HK-Ya-hk-y][0-9]([0-9]|[ABEHMNPRV-Yabehmnprv-y])?|[0-9]([0-9]|[A-HJKPSTUWa-hjkpstuw])?) ?[0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2})" />
            <span class="icon is-large is-left">
              <i class="fa-map-marker fal"></i>
            </span>
          </div>

        </div>

      </div>
    </div>

    <div class="level is-mobile" style="width: 100%">

      <div class="control is-flex has-items-right" style="width: 100%">
        <button class="button whitespace-wrap is-action is-fullwidth is-outlined" type="submit">
          Get your instant quote
        </button>
      </div>
    </div>
  </form>
</template>
