<template>
  <footer class="footer has-border-top border-thick border-is-grey-lighter tw-px-0">
    <div class="container is-fullhd tw-px-6">
      <div class="tw-grid tw-grid-cols-2 md:tw-grid-cols-4">
        <div class="tw-grid tw-grid-cols-2 md:tw-grid-cols-1 tw-col-span-2 md:tw-col-span-1 tw-mb-3 md:tw-mb-0">
          <div class="tw-text-sm md:tw-text-lg">
            <p class="md:tw-mt-3">
              <strong>Address</strong>
            </p>
            <address>
              <p>59-61,</p>
              <p>Old Kent Road,</p>
              <p>London, SE1 4RF </p>
              <p>0207 237 4545</p>
            </address>
            <hr class="tw-w-1/2 tw-h-px tw-bg-slate-300 tw-hidden md:tw-block tw-my-3">
          </div>
          <div class="tw-text-sm md:tw-text-lg">
            <p><strong>Links</strong></p>
            <ul class="md:tw-mb-3">
              <li>
                <router-link
                  class="has-text-dark"
                  to="/"
                >
                  Home
                </router-link>
              </li>
              <li>
                <router-link
                  class="has-text-dark"
                  to="/contact-us"
                  title="Contact us"
                >
                  Contact us
                </router-link>
              </li>
              <li>
                <router-link
                  class="has-text-dark"
                  to="/customer-support"
                  title="Customer support"
                >
                  Customer
                  support
                </router-link>
              </li>
              <li>
                <router-link
                  class="has-text-dark"
                  to="/discover-print-savings"
                  title="Discover print savings"
                >
                  Discover savings
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="tw-text-sm md:tw-text-lg">
          <p><strong>Services</strong></p>
          <ul>
            <li>
              <router-link
                class="has-text-dark"
                to="/services/leasing-and-purchase"
              >
                Lease & purchase
              </router-link>
            </li>
            <li>
              <router-link
                class="has-text-dark"
                to="/services/service-agreements"
              >
                Service agreements
              </router-link>
            </li>
            <li>
              <router-link
                class="has-text-dark"
                to="/services/repairs-and-servicing"
              >
                Repairs & servicing
              </router-link>
            </li>
            <li>
              <router-link
                class="has-text-dark"
                to="/services/integrations"
              >
                Integrations
              </router-link>
            </li>
            <li>
              <router-link
                class="has-text-dark"
                to="/services/document-management"
              >
                Document management
              </router-link>
            </li>
            <li>
              <router-link
                class="has-text-dark"
                to="/services/document-security"
              >
                Document security
              </router-link>
            </li>
            <li>
              <router-link
                class="has-text-dark"
                to="/services/remote-care"
              >
                Remote care
              </router-link>
            </li>
            <li>
              <router-link
                class="has-text-dark"
                to="/services/print-audit"
              >
                Print audit
              </router-link>
            </li>
            <li>
              <router-link
                class="has-text-dark"
                to="/services/print-cost-control"
              >
                Print cost control
              </router-link>
            </li>
          </ul>
        </div>
        <div class="tw-text-sm md:tw-text-lg">
          <p><strong>Equipment</strong></p>
          <ul>
            <li>
              <router-link
                class="has-text-dark"
                to="/printers/multifunctionals/a3-colour"
                title="A3 Colour multifunctional printers"
              >
                A3 Colour MFPs
              </router-link>
            </li>
            <li>
              <router-link
                class="has-text-dark"
                to="/printers/multifunctionals/a3-mono"
                title="A3 Mono multifucntional printers"
              >
                A3 Mono MFPs
              </router-link>
            </li>
            <li>
              <router-link
                class="has-text-dark"
                to="/printers/multifunctionals/a4-colour"
                title="A4 Colour multifunctional printers"
              >
                A4 Colour MFPs
              </router-link>
            </li>
            <li>
              <router-link
                class="has-text-dark"
                to="/printers/multifunctionals/a4-mono"
                title="A4 Mono multifunctional printers"
              >
                A3 Mono MFPs
              </router-link>
            </li>
            <li>
              <router-link
                class="has-text-dark"
                to="/printers/print-only/a3-mono"
                title="A3 Mono office printers"
              >
                A3
                Mono Printers
              </router-link>
            </li>
            <li>
              <router-link
                class="has-text-dark"
                to="/printers/print-only/a3-colour"
                title="A3 Colour office printers"
              >
                A3 Colour Printers
              </router-link>
            </li>
            <li>
              <router-link
                class="has-text-dark"
                to="/printers/print-only/a4-colour"
                title="A4 Colour office printers"
              >
                A4 Colour Printers
              </router-link>
            </li>
            <li>
              <router-link
                class="has-text-dark"
                to="/printers/print-only/a4-mono"
                title="A4 Mono office printers"
              >
                A3
                Mono Printers
              </router-link>
            </li>
            <li>
              <router-link
                class="has-text-dark"
                to="/printers/production/colour"
                title="Colour production printers"
              >
                Colour Production Printers
              </router-link>
            </li>
            <li>
              <router-link
                class="has-text-dark"
                to="/printers/production/mono"
                title="Mono production printers"
              >
                Mono
                Production Printers
              </router-link>
            </li>
          </ul>
        </div>

        <div class="tw-col-span-2 md:tw-col-span-1 tw-mt-3 md:tw-mt-0">
          <p class="tw-mb-0">
            <strong>Newsletter</strong>
          </p>
          <p class="tw-leading-3 tw-mb-3">
            <small>Sign up to our newsletter</small>
          </p>

          <MauticForm />
        </div>

        <div class="tw-col-span-2 md:tw-col-span-4 tw-mt-3 md:tw-mt-0">
          <a
            href="https://www.iubenda.com/privacy-policy/11449757"
            title="Privacy Policy"
            rel="nofollow"
          > Privacy
            policy</a>
          <p>&copy; Copytech Group {{ currentYear }}.</p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import MauticForm from './MauticForm.vue'
export default {
  name: 'SiteFooter',
  components: {
    MauticForm
  },
  computed: {
    currentYear() {
      const today = new Date()
      return today.getFullYear()
    }
  }
}
</script>
