<template>
  <div class="navbar-start">
    <!-- Mega menu item -->
    <MegaMenu
      heading="Products"
      :content="equipment"
      @navigate="handleNavigationClick"
    />

    <div
      class="navbar-item has-dropdown"
      @click.stop="itemClicked"
      @mouseenter.stop="itemHoverOn"
      @mouseleave.stop="itemHoverOff"
    >
      <a class="navbar-link is-hidden-touch">Services</a>
      <div class="navbar-dropdown is-right">
        <div class="navbar-item no-padding-top no-padding-bottom">
          <p class="is-size-6-desktop u-no-margin">
            <strong class="has-text-info">Services</strong>
          </p>
        </div>
        <router-link
          v-for="(service) in services.services.items"
          :key="service.url"
          class="navbar-item dropdown-item-icon flex-mobile has-space-between"
          :to="service.url"
          exact-active-class="has-text-primary tw-bg-zinc-100"
          @click="handleNavigationClick"
        >
          <p>{{ service.text }}</p><span class="icon match-line"><i
            class="fal"
            :class="service.icon"
          /></span>
        </router-link>
        <hr class="navbar-divider is-hidden-desktop block-touch">
      </div>
    </div>

    <router-link
      class="navbar-item flex-mobile has-space-between is-hidden-touch tw-py-6"
      to="/customer-support"
      active-class="is-active"
      @click="handleNavigationClick"
    >
      <span>Support</span><span
        class="icon match-line is-hidden-desktop"
      ><i class="fal fa-chevron-right" /></span>
    </router-link>
    <!-- <a class="navbar-item flex-mobile has-space-between is-hidden-touch" href="/blog/" active-class="is-active"
      @click.stop="handleNavigationClick"><span>Blog</span><span class="icon match-line is-hidden-desktop"><i
          class="fal fa-chevron-right"></i></span>
    </a> -->

    <router-link
      class="navbar-item flex-mobile has-space-between is-hidden-touch"
      to="/contact-us"
      active-class="is-active"
      @click.stop="handleNavigationClick"
    >
      <span>Contact</span><span
        class="icon match-line is-hidden-desktop"
      ><i class="fal fa-chevron-right" /></span>
    </router-link>

    <router-link
      class="navbar-item flex-mobile has-space-between is-hidden-touch"
      to="/discover-print-savings"
      active-class="is-active"
      @click.stop="handleNavigationClick"
    >
      <span>Discover savings</span><span
        class="icon match-line is-hidden-desktop"
      ><i class="fal fa-pound-sign" /></span>
    </router-link>

    <div class="navbar-dropdown is-right">
      <router-link
        class="navbar-item dropdown-item-icon flex-mobile has-space-between is-hidden-desktop"
        to="/customer-support"
        active-class="is-active"
        @click.stop="handleNavigationClick"
      >
        <span>Support</span><span
          class="icon match-line"
        ><i class="fal fa-chevron-right" /></span>
      </router-link>
    </div>

    <!-- <hr class="navbar-divider is-hidden-desktop block-touch" /> -->

    <!-- <div class="navbar-dropdown is-right">
      <a class="navbar-item dropdown-item-icon flex-mobile has-space-between is-hidden-desktop" href="/blog/"
        active-class="is-active" @click.stop="handleNavigationClick">
        <span>Blog</span>
        <span class="icon match-line">
          <i class="fal fa-chevron-right"></i>
        </span>
      </a>
    </div> -->

    <!-- <hr class="navbar-divider is-hidden-desktop block-touch" /> -->
    <div class="navbar-dropdown is-right">
      <router-link
        class="navbar-item dropdown-item-icon flex-mobile has-space-between is-hidden-desktop"
        to="/contact-us"
        active-class="is-active"
        @click.stop="handleNavigationClick"
      >
        <span>Contact</span><span
          class="icon match-line"
        ><i class="fal fa-chevron-right" /></span>
      </router-link>
    </div>
    <div class="navbar-dropdown is-right">
      <router-link
        class="navbar-item dropdown-item-icon flex-mobile has-space-between is-hidden-desktop"
        to="/discover-print-savings"
        active-class="is-active"
        @click.stop="handleNavigationClick"
      >
        <span>Discover
          savings</span><span class="icon match-line"><i class="fal fa-pound-sign" /></span>
      </router-link>
    </div>
    <slot name="basket" />
  </div>
</template>
<script>
// import Icon from '../Icon.vue'
// import ColourWheel from '../ColourWheel.vue'
// import PaperIcon from '../PaperIcon.vue'
// import BasketToggle from '../BasketToggle.vue'
import { debounce } from 'lodash-es'

// Navigation / megamenu
import MegaMenu from './MegaMenu.vue'
// import NavDropdown from './NavDropdown.vue'

const leaveDropdown = debounce((cb) => {
  cb()
}, 40)

export default {
  name: 'NavigationMenu',
  components: {
    MegaMenu
  },
  props: ['deviceNav'],
  data() {
    return {
      openItems: '',
      hovering: false,
      tools: [
        {
          text: 'Discover savings',
          url: '/discover-print-savings',
          icon: 'fa-pound-sign',
        },
      ],
      equipment: {
        mfp: {
          heading: 'Multifunctionals',
          items: [
            {
              text: 'A3 Colour',
              url: '/printers/multifunctionals/a3-colour',
              flyoutIcon: 'full-colour',
              icon: 'full-colour',
            },
            {
              text: 'A3 Mono',
              url: '/printers/multifunctionals/a3-mono',
              flyoutIcon: 'mono',
              icon: 'mono',
            },
            {
              text: 'A4 Colour',
              url: '/printers/multifunctionals/a4-colour',
              flyoutIcon: 'full-colour',
              icon: 'full-colour',
            },
            {
              text: 'A4 Mono',
              url: '/printers/multifunctionals/a4-mono',
              flyoutIcon: 'mono',
              icon: 'mono',
            },
            {
              text: 'View all',
              url: '/printers/multifunctionals',
              flyoutIcon: 'mono',
              icon: 'fa-chevron-right',
            },
          ]
        },
        production: {
          heading: 'Production',
          items: [
            {
              text: 'Colour',
              url: '/printers/production/colour',
              flyoutIcon: 'full-colour',
              icon: 'full-colour',
            },
            {
              text: 'Mono',
              url: '/printers/production/mono',
              flyoutIcon: 'mono',
              icon: 'mono',
            },
            {
              text: 'View all',
              url: '/printers/production',
              flyoutIcon: 'mono',
              icon: 'fa-chevron-right',
            },
          ]
        },
        plotter: {
          heading: 'Plotters',
          items: [
            {
              text: 'Colour',
              url: '/printers/plotter/colour',
              flyoutIcon: 'full-colour',
              icon: 'full-colour',
            },
            {
              text: 'Mono',
              url: '/printers/plotter/mono',
              flyoutIcon: 'mono',
              icon: 'mono',
            },
            {
              text: 'View all',
              url: '/printers/plotter',
              flyoutIcon: 'mono',
              icon: 'fa-chevron-right',
            },
          ]
        },
        printOnly: {
          heading: 'Printers',
          items: [
            {
              text: 'A3 Colour',
              url: '/printers/print-only/a3-colour',
              flyoutIcon: 'full-colour',
              icon: 'full-colour',
            },
            {
              text: 'A3 Mono',
              url: '/printers/print-only/a3-mono',
              flyoutIcon: 'mono',
              icon: 'mono',
            },
            {
              text: 'A4 Colour',
              url: '/printers/print-only/a4-colour',
              flyoutIcon: 'full-colour',
              icon: 'full-colour',
            },
            {
              text: 'A4 Mono',
              url: '/printers/print-only/a4-mono',
              flyoutIcon: 'mono',
              icon: 'mono',
            },
            {
              text: 'View all',
              url: '/printers/print-only',
              flyoutIcon: 'mono',
              icon: 'fa-chevron-right',
            },
          ]
        },
        scanners: {
          heading: 'Scanners',
          items: [
            {
              text: 'Personal',
              url: '/scanners/personal',
              icon: 'fa-scanner-image',
            },
            {
              text: 'Workgroup',
              url: '/scanners/desktop-workgroup',
              icon: 'fa-user-group',
            },
            {
              text: 'Departmental',
              url: '/scanners/departmental',
              icon: 'fa-network-wired',
            },
            {
              text: 'Production',
              url: '/scanners/production',
              icon: 'fa-industry',
            },
            {
              text: 'View all',
              url: '/scanners/all',
              icon: 'fa-chevron-right',
            },
          ]
        }
      },
      services: {
        services: {
          heading: 'Services',
          items: [
            {
              text: 'Lease & purchase',
              url: '/services/leasing-and-purchase',
              icon: 'fa-credit-card',
            },
            {
              text: 'Service agreements',
              url: '/services/service-agreements',
              icon: 'fa-cubes',
            },
            {
              text: 'Repairs & servicing',
              url: '/services/repairs-and-servicing',
              icon: 'fa-wrench',
            },
            {
              text: 'Integrations',
              url: '/services/integrations',
              icon: 'fa-puzzle-piece',
            },
            {
              text: 'Document management',
              url: '/services/document-management',
              icon: 'fa-file-alt',
            },
            {
              text: 'Document security',
              url: '/services/document-security',
              icon: 'fa-shield-alt',
            },
            {
              text: 'Remote care',
              url: '/services/remote-care',
              icon: 'fa-eye',
            },
            {
              text: 'Print audit',
              url: '/services/print-audit',
              icon: 'fa-chart-pie',
            },
            {
              text: 'Print cost control',
              url: '/services/print-cost-control',
              icon: 'fa-chart-bar',
            },
            {
              text: 'Papercut',
              url: '/services/papercut',
              icon: 'fa-file-alt',
            },
          ]
        }
      },
    }
  },
  serverCacheKey: () => {
    return true
  },
  methods: {
    handleNavigationClick(e) {
      // Template has handle click oin dropdowns, is used in mobile nav but
      // we need a function here to not error out
      // if (this.deviceNav) {
      // let dropdown
      // let parent
      // if (e.target) {
      //   dropdown = e.target.parentElement
      //   parent = e.target.parentElement.parentElement
      // } else if (e.srcElement) {
      //   dropdown = e.srcElement.parentElement
      //   parent = e.srcElement.parentElement.parentElement
      // }

      this.$emit('toggle-back')
      this.$emit('navigate', e)
      // console.log('beeep')
      this.$searchAncestors(
        e.target,
        'is-active',
        (el) => {
          el.classList.remove('is-active')
          // console.log('we found it')
        },
        'nav-item'
      )
    },
    // Handle clicking of menu sub-item
    itemClicked(e) {
      // Only needed on device menus, as larger screens show all options at once
      if (this.deviceNav) {
        // Add active class to element and push onto array tacking elements
        e.currentTarget.classList.add('is-active')
        // this.openItems.push(e.currentTarget)
        this.$emit('toggle-back', true)
      }
    },
    itemHoverOn(e) {
      // Start capturing mouse move events
      if (e.currentTarget === this.openItems) {
        leaveDropdown.cancel()
      } else {
        e.target.classList.add('is-active')
        this.openItems = e.currentTarget
      }
    },
    itemHoverOff(e) {
      // When moving offhover item, wait a small amount of time before removing class, in case
      // we are just moving to dropdown
      leaveDropdown(() => {
        e.target.classList.remove('is-active')
        this.openItems = ''
      })
    },
  },
}
</script>
