// import Vue from 'vue'
// import { createStore } from 'vuex'

// Import modules
import appState from './modules/appState'
import basket from './modules/basket'
import blog from './modules/blog'
import modal from './modules/modal'
// import pages from './modules/pages'
import printerFilter from './modules/printer-filter'
/* The line `import products from './modules/products'` is importing the `products` module from
the `./modules` directory. This module is likely a part of a larger Vuex store configuration
and contains state, mutations, actions, and getters related to managing products in the application. */
// import products from './modules/products'
import serviceChecker from './modules/service-checker'

let storeConfig = {
  modules: {
    appState,
    basket,
    blog,
    modal,
    // pages,
    printerFilter,
    // products,
    serviceChecker
  },
  plugins: []
}

// Vue.use(Vuex)

// export function createStore() {
//   return new Vuex.Store(storeConfig)
// }

// export default createStore(storeConfig)
export { storeConfig }