import Blog from './BlogApi'
import Form from './FormApi'
import * as Page from './PageApi'
import * as Product from './ProductApi'

const Api = {
  Blog,
  Form,
  Page,
  Product,
}

export default function install(app) {
  app.config.globalProperties.api = Api
  app.config.globalProperties.$api = Api
}
