// initial state
const state = {
  open: false,
  component: ''
}

const actions = {
  openModal: ({ commit }, component) => {
    // Freeze html
    // const html = document.documentElement
    // const widget = document.getElementById('fxo-widget-iframe')
    // html.classList.add('u-no-scroll')
    // HIde fxoWidget
    // try {
    //   widget.style.visibility = 'hidden'
    // } catch (e) { }

    commit('OPEN_MODAL', component)
  },
  switchModal: ({ commit }, component) => {
    commit('SWITCH_MODAL', component)
  },
  closeModal: ({ commit }) => {
    // var html = document.documentElement
    // html.classList.remove('u-no-scroll')
    commit('CLOSE_MODAL')
  }
}

// mutations
const mutations = {
  OPEN_MODAL(state, component) {
    state.component = component
    // state.open = true
  },
  SWITCH_MODAL(state, component) {
    state.component = component
  },
  CLOSE_MODAL(state) {
    state.component = ''
    state.open = false
  }
}

// getters
const getters = {
  getModalStatus: state => state.open,
  getModalComponent: state => state.component
}

export default { state, mutations, getters, actions }
