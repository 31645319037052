<script lang="ts">

</script>
<template>
  <div
    id="mauticform_wrapper_copytechgroupnewsletterform"
    class="mauticform_wrapper"
  >
    <form
      id="mauticform_copytechgroupnewsletterform"
      autocomplete="false"
      method="post"
      action="https://marketing.copytechgroup.co.uk/form/submit?formId=1"
      data-mautic-form="copytechgroupnewsletterform"
      enctype="multipart/form-data"
      target="mauticiframe_copytechgroupnewsletterform"
    >
      <div
        id="mauticform_copytechgroupnewsletterform_error"
        class="mauticform-error"
      />
      <div
        id="mauticform_copytechgroupnewsletterform_message"
        class="mauticform-message"
      />
      <div class="mauticform-innerform">
        <div
          class="mauticform-page-wrapper mauticform-page-1"
          data-mautic-form-page="1"
        >
          <div
            id="mauticform_copytechgroupnewsletterform_first_name"
            class="mauticform-row mauticform-text mauticform-field-1"
          >
            <div class="field">
              <input
                id="mauticform_input_copytechgroupnewsletterform_first_name"
                class="input mauticform-input"
                name="mauticform[first_name]"
                value=""
                placeholder="Jane"
                type="text"
              ><span
                class="mauticform-errormsg"
                style="display: none;"
              />
            </div>
          </div>
          <div
            id="mauticform_copytechgroupnewsletterform_email"
            class="mauticform-row mauticform-email mauticform-field-2 mauticform-required"
            data-validate="email"
            data-validation-type="email"
          >
            <div class="field">
              <input
                id="mauticform_input_copytechgroupnewsletterform_email"
                class="input mauticform-input"
                name="mauticform[email]"
                value=""
                placeholder="jane@example.com"
                type="email"
              >
            </div><span
              class="mauticform-errormsg"
              style="display: none;"
            >The email is a required field.</span>
          </div>
          <div
            id="mauticform_copytechgroupnewsletterform_submit"
            class="mauticform-row mauticform-button-wrapper mauticform-field-3"
          >
            <button
              id="mauticform_input_copytechgroupnewsletterform_submit"
              class="button is-success whitespace-wrap mauticform-button btn btn-default"
              type="submit"
              name="mauticform[submit]"
              value=""
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <input
        id="mauticform_copytechgroupnewsletterform_id"
        type="hidden"
        name="mauticform[formId]"
        value="1"
      >
      <input
        id="mauticform_copytechgroupnewsletterform_return"
        type="hidden"
        name="mauticform[return]"
        value=""
      >
      <input
        id="mauticform_copytechgroupnewsletterform_name"
        type="hidden"
        name="mauticform[formName]"
        value="copytechgroupnewsletterform"
      >
    </form>
  </div>
</template>