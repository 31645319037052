import "./styles/typekit.css";
// import './styles/purged-copytech.css'
import "./styles/copytech.css";
import "./styles/fontawesome/fontawesome.css";
import "./styles/fontawesome/light.css";
import "./styles/extra-styles.css";

// Tailwind
import "./styles/main.css";

import { createApp } from "./main";

const { app, router, pinia } = createApp();

// prime the store with server-initialized state.
// the state is determined during SSR and inlined in the page markup.
if (window.__pinia) {
  pinia.state.value = window.__pinia;
}

router.isReady().then(() => {
  app.mount("#app");
});
