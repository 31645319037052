<template>
  <aside class="slide-menu is-hidden-tablet">
    <nav class="nav">
      <navigation
        class="nav-menu"
        device-nav="true"
        :class="{ 'is-active': isActive }"
        @toggle-back="toggleBack"
        @navigate="toggleSlideMenu"
      />
    </nav>
  </aside>
</template>
<script>
// import ComparisonBasket from './ComparisonBasket.vue'
// import SlideMenuNavigation from './navigation/SlideMenuNavigation.vue'
import Navigation from './navigation/Navigation.vue'
// import MobileNavToggle from './MobileNavToggle.vue'

export default {
  components: {
    // ComparisonBasket,
    // MobileNavToggle,
    Navigation
  },
  props: ['menuShow', 'slideComponent'],
  data() {
    return {
      isActive: true,
      deviceNavOpen: false
    }
  },
  watch: {
    menuShow: 'showMenu'
  },
  methods: {
    closeBasket() {
      this.$store.dispatch('closeBasket')
    },
    toggleSlideMenu() {
      this.$emit('toggle-nav')
    },
    toggleBack(val) {
      this.$emit('toggle-back', val)
    }, // Toggle back button after selecting item
    showMenu() {
      if (this.menuShow === true) {
        this.$el.classList.add('active')
      } else {
        this.$el.classList.remove('active')
      }
    }
  }
}
</script>
