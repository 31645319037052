<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <component :is="component" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

// Import modal components
import ModalGallery from './ModalGallery.vue'
import InstantBasketItems from './forms/InstantBasketItems.vue'
import Confirmation from './Confirmation.vue'
// import QuoteDetails from './forms/QuoteDetails.vue'
import QuoteDetails from './forms/Details.vue'
// import StaticForm from './form/StaticForm.vue'
// import Confirmation from './Confirmation.vue'
import Icon from './Icon.vue'

export default {
  name: 'ModalContainer',
  components: {
    ModalGallery,
    InstantBasketItems,
    QuoteDetails,
    Confirmation,
    Icon
  },
  data() {
    return {}
  },
  computed: mapGetters({
    open: 'getModalStatus',
    component: 'getModalComponent'
  }),
  mounted() {
    // Add hash to location so we can reverse it on closing the modal
    window.location.hash = 'modal'
    window.addEventListener('keyup', this.handleKeys)
    window.addEventListener('popstate', this.handleBack, {
      once: true
    })
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.handleKeys)
    window.removeEventListener('keyup', this.handleBack)
  },
  methods: {
    ...mapActions(['closeModal']),
    handleKeys(e) {
      if (e.keyCode === 27) {
        this.closeModal()
      }
    },
    handleBack() {
      this.closeModal()
      // Stay on the current page.
      return false
    }
  },
 
}
</script>
