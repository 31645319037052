import { get } from 'lodash-es'
// Helper functiosn to do with URLS (e.g. getting the current URL or creating a slug)
export function createSlug(str) {
  const slug = str
    .toLowerCase()
    .trim()
    // Remove first forward slash and trailing slash
    .replace(/\/$/, '')
    .replace(/^\//, '')
    // Replace forward slashes with spaces
    .replace(/\//g, ' ')
    .replace(/[^a-z0-9\s-]/g, '')
    .replace(/[\s-]+/g, '-');

  return slug
}

export function stringToSlug(string) {
  const spaceRE = /\s+/gi
  // Ensure string is ready to be used in url
  // All lowercase, spaces are dashes, no bad characters
  const formatted = string.replace(spaceRE, '-').toLowerCase()
  return formatted
}
export function slugToString(slug) {
  // Convert slug back into string
  const dashRE = /-+/gi
  const formatted = slug.replace(dashRE, ' ')
  return formatted
}


export function updateDataLayer(key, value) {
  // Check data layer existrs
  if (window.dataLayer) {
    let obj = {
      [key]: value
    }
    window.dataLayer.push(obj)
  }
}

export function fbAddToCartEvent(product) {
  if (window.fbq) {
    window.fbq('track', 'AddToCart', {
      content_ids: [product.modelId],
      content_name: product.brand + product.model
    })
  }
}

export function fbLeadEvent() {
  if (window.fbq) {
    window.fbq('track', 'Lead')
  }
}

export function vuexModel(prop, namespace) {
  return {
    get: function () {
      const pennyTargets = ['colourCostPerPage', 'monoCostPerPage']
      const poundTargets = ['cost', 'monthlyEquipmentCost', 'bills[0].cost', 'bills[1].cost']
      let value
      let divide
      if (pennyTargets.indexOf(prop) > -1) {
        divide = 1000
      } else if (poundTargets.indexOf(prop) > -1) {
        divide = 100 * 1000
      } else {
        divide = false
      }
      if (namespace) {
        if (divide) {
          value = get(this.$store.state, `${namespace}.${prop}`) / divide
        } else {
          value = get(this.$store.state, `${namespace}.${prop}`)
        }
      } else {
        if (divide) {
          value = get(this.$store.state, prop) / divide
        } else {
          value = get(this.$store.state, prop)
        }
      }
      return value
    },
    set: function (v) {
      const pennyTargets = ['colourCostPerPage', 'monoCostPerPage']
      const poundTargets = ['cost', 'monthlyEquipmentCost', 'bills[0].cost', 'bills[1].cost']
      let value
      let mult
      if (pennyTargets.indexOf(prop) > -1) {
        mult = 1000
      } else if (poundTargets.indexOf(prop) > -1) {
        mult = 100 * 1000
      } else {
        mult = false
      }
      if (mult) {
        value = v * mult
      } else {
        value = v
      }

      this.$store.commit('SET_VALUE', { target: prop, value: value })
    }
  }
}

// List of utm parameters and other google advertising parameters
const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'gclid']

// Get the utm data from the URL, pass query from this.$route.query.
export function getUTMFromQuery(query) {
  const utmData = {}

  // Check query is an object
  if (typeof query !== 'object') {
    return false
  }

  utmParams.forEach(param => {
    if (query[param]) {
      utmData[param] = query[param]
    }
  })

  // Return false if no utm data
  if (Object.keys(utmData).length === 0) {
    return false
  }

  return utmData
}

//  Get utm data from local storage
export function getUTMFromLocalStorage() {
  const utmData = JSON.parse(localStorage.getItem('utm_data') || '{}')

  // Return false if no utm data
  if (Object.keys(utmData).length === 0) {
    return false
  }

  return utmData
}