<template>
  <div
    v-if="status !== &quot;accept&quot;"
    class="is-fixed is-bottom is-flex has-items-left pad-single"
  >
    <div class="box has-border pad-single border-thin border-is-grey-light">
      <p class="is-copy paragraph">
        🍪 We use cookies to make your experience on this website better.
      </p>
      <div class="is-flex buttons has-items-right">
        <button
          class="button is-success pad-left pad-right"
          @click="accept"
        >
          Accept
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const isServer = import.meta.env.SSR

export default {
  data() {
    return {
      statusKey: 'cookie-acceptance-status',
      status: 'accept'
    }
  },
  mounted() {
    this.status = this.getCookieStatus()
  },
  // computed: {
  //   cookiesAccepted () {
  //     return this.status === 'accept'
  //   }
  // },
  methods: {
    getCookieStatus() {
      if (!isServer) {
        const status = localStorage.getItem(this.statusKey)
        if (status === 'accept') return 'accept'
        return 'declined'
      }
    },
    setCookieStatus(type) {
      if (!isServer) {
        localStorage.setItem(this.statusKey, type)
      }
    },
    accept() {
      this.setCookieStatus('accept')
      this.status = 'accept'
    },
    decline() {
      this.setCookieStatus('decline')
    }
  }
}
</script>