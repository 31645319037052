<template>
  <svg>
    <use :xlink:href="`#${icon}`" />
  </svg>
</template>

<script>
export default {
  name: 'NewIcon',
  serverCacheKey (props) {
    return 'vue-icon-' + props.icon
  },
  props: ['icon']
}
</script>

