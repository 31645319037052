/**
 * Implements common methods related to products
 */
import { find } from 'lodash-es'
import { stringToSlug } from '../util'

export function asPounds(valueInPence, divisor = 100, shorten = false) {
  // Create a NumberFormat instance for British pounds
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    // minimumFractionDigits: 0,
    // maximumFractionDigits: 0
  })
  // Format the value in pence as a string with two decimal places
  const valueString = parseFloat(valueInPence / divisor).toFixed(2)
  // Format the value string as a currency string
  const currencyString = formatter.format(valueString)
  // If shorten is true, remove the thousands separator
  if (shorten) {
    return currencyString.replace(/,/g, '')
  }
  // Otherwise return the currency string
  return currencyString
}
const baseUrl = 'https://assets.copytechgroup.co.uk'
const pdfBaseUrl = 'https://assets.copytechgroup.co.uk'
const RE = /\s/gi // Regex for Whitespace

export function getBrandModelId(brand, model) {
  const re = /[^a-zA-Z0-9+_()]/ig
  const brandModel = brand + model

  if (brandModel) return brandModel.replace(re, '').toLowerCase()
  else return ''
}

function convertDimensionsToVolume(dimensions) {
  const vals = dimensions.replace(/\s/g, '').replace(/[A-WYZ,]+/ig, '').toLowerCase().split('x');

  if (vals.length !== 3 || vals.some((val) => isNaN(val))) {
    return 0;
  }

  const volume = vals.reduce((a, b) => Number(a) * Number(b));
  return volume;
}

// export function getPrinterSize(dimensions, type, paperSize) {
export function getPrinterSize(dimensions) {
  const volume = convertDimensionsToVolume(dimensions);

  // if (type === 'multifunctional-printers') {
  if (volume <= 0) return 'standard';
  if (volume <= 100000000) return 'compact';
  if (volume <= 250000000) return 'small';
  if (volume <= 400000000) return 'medium sized';
  if (volume <= 650000000) return 'large';
  return 'extra large';
  // }
  //  else {
  //   if (volume <= 0 || !['A3', 'A4'].includes(paperSize.toUpperCase())) return 'unknown';
  //   if (paperSize.toUpperCase() === 'A4') return 'compact';
  //   return 'standard';
  // }
}

// Dictionary of values
const typeDictionary = {
  'printer': 'printer',
  'multifunctional-printers': 'multifunctional printer',
  'print-only': 'single function printer',
  'plotter': 'plotter',
  'heavy-production': 'production printer',
  'heavy-production-printers': 'production printer',
  'mid-production': 'production printer',
  'mid-production-printers': 'production printer',
  'light-production': 'production printer',
  'light-production-printers': 'production printer',
  'production-scanners': 'production scanner',
  'departmental-scanners': 'departmental scanner',
  'desktop-workgroup-scanners': 'desktop scanner',
  'personal-scanners': 'personal scanner',
  'scanner': 'scanner',
}

function getProductType(type) {
  switch (type) {
    case 'multifunctional-printers':
      return 'printer'
    case 'print-only':
      return 'printer'
    case 'plotter':
      return 'printer'
    case 'heavy-production':
      return 'printer'
    case 'mid-production':
      return 'printer'
    case 'light-production':
      return 'printer'
    case 'scanner':
      return 'scanner'
    case 'production-scanners':
      return 'scanner'
    case 'departmental-scanners':
      return 'scanner'
    case 'desktop-workgroup-scanners':
      return 'scanner'
    case 'personal-scanners':
      return 'scanner'
    default:
      return 'printer'
  }
}

const getValueInArray = function (array, key) {
  return find(array, item => {
    return item.name === key
  }).value
}


function speedString(product) {
  if (product.productType === 'scanner') {
    return scanSpeedString(product.speed)
  }
  return printSpeedString(product.speed)
}

function printSpeedString(speed) {
  let description;

  if (speed <= 10) {
    description = "suitable for low-volume printing needs";
  } else if (speed <= 20) {
    description = "capable of handling moderate print volumes";
  } else if (speed <= 30) {
    description = "ideal for medium-sized offices and workgroups";
  } else if (speed <= 40) {
    description = "designed for high-volume printing environments";
  } else if (speed <= 50) {
    description = "capable of handling demanding print workloads";
  } else if (speed <= 70) {
    description = "delivering exceptional print speeds for high-throughput needs";
  } else if (speed <= 100) {
    description = "among the fastest printers in its class";
  } else {
    description = "exceptionally fast print speeds for production printing";
  }

  return `Print speeds of up to ${speed} pages per minute, ${description}`;
}

function scanSpeedString(speed) {
  let description;

  if (speed <= 10) {
    description = "suitable for low-volume scanning needs";
  } else if (speed <= 20) {
    description = "capable of handling moderate scanning volumes";
  } else if (speed <= 30) {
    description = "ideal for medium-sized offices and workgroups";
  } else if (speed <= 40) {
    description = "designed for high-volume scanning environments";
  } else if (speed <= 50) {
    description = "capable of handling demanding scanning workloads";
  } else if (speed <= 70) {
    description = "delivering exceptional scanning speeds for high-throughput needs";
  } else if (speed <= 100) {
    description = "among the fastest scanners in its class";
  } else {
    description = "exceptionally fast scanning speeds for production scanning";
  }

  return `Scanning speeds of up to ${speed} pages per minute, ${description}`;
}

const getCapacitySentence = function (capacity) {
  return capacity
}

function getProductOffer(product) {
  if (product.productType === 'scanner') {
    return getScannerOffer(product)
  }
  return getPrinterOffer(product)

}

function getPrinterOffer(product) {
  switch (product.brand) {
    case "Konica Minolta":
      return `${product.brand} printers offer high-quality and cost-effective print output with exceptional reliability and a focus on environmental sustainability.`;
    case "Kyocera":
      return `${product.brand} printers deliver economical print output with long-lasting consumables and a focus on reducing printing costs.`;
    case "Toshiba":
      return `${product.brand} printers provide reliable and efficient print output with advanced features and a focus on user-friendliness.`;
    case "Canon":
      return `${product.brand} printers offer consistent and high-quality print output with innovative technologies and a focus on productivity.`;
    case "Ricoh":
      return `${product.brand} printers deliver dependable and secure print output with a focus on innovation and adaptability to diverse printing needs.`;
    case "Xerox":
      return `${product.brand} printers provide versatile and scalable print output with a focus on streamlining workflows and enhancing collaboration.`;
    case "Epson":
      return `${product.brand} printers deliver precise and durable print output with a focus on precision and attention to detail.`;
    default:
      return `${product.brand} printers offer quality print output with a focus on meeting your specific printing needs.`;
  }
}

function getScannerOffer(product) {
  switch (product.brand) {
    case "Epson":
      return "It delivers high-resolution scanning with exceptional accuracy and a focus on preserving image quality.";
    case "Fujitsu":
      return "It provides reliable and efficient scanning with a focus on document management and workflow automation.";
    case "Canon":
      return "It offers versatile and scalable scanning with a focus on adaptability to diverse scanning needs.";
    case "Brother":
      return "It delivers compact and portable scanners with a focus on user-friendliness and convenience.";
    case "Kodak":
      return "It provides high-quality scanning solutions with a focus on image enhancement and restoration.";
    case "Plustek":
      return "It offers innovative and feature-rich scanners with a focus on productivity and efficiency.";
    case "HP":
      return "It delivers versatile scanning solutions with a focus on connectivity and integration into various workflows.";
    default:
      return "It offers quality scanning performance with a focus on meeting your specific scanning needs.";
  }
}



function getPerfectForPrinter({ brand, colourPrinting }) {
  let description;

  switch (brand) {
    case "Konica Minolta":
      if (colourPrinting === "full-colour") {
        description = "is ideal for producing high-quality, vibrant graphics and presentations, perfect for design professionals, marketing teams, and educators";
      } else {
        description = "is a reliable and cost-effective solution for everyday printing tasks, suitable for offices, home offices, and businesses of all sizes";
      }
      break;

    case "Kyocera":
      if (colourPrinting === "full-colour") {
        description = "is designed to minimize environmental impact while delivering exceptional colour output, making it a great choice for eco-conscious businesses";
      } else {
        description = "is a budget-friendly option that offers economical mono printing, ideal for businesses prioritizing cost-efficiency without compromising quality";
      }
      break;

    case "Canon":
      if (colourPrinting === "full-colour") {
        description = "is a versatile solution for handling a wide range of colour documents, from presentations to marketing materials, perfect for busy offices and creative professionals";
      } else {
        description = "enhances productivity and efficiency in mono printing environments, ideal for offices with high-volume document printing needs";
      }
      break;

    case "Epson":
      if (colourPrinting === "full-colour") {
        description = "is delivers precise and durable colour output, making it a great choice for businesses that demand high-quality prints for brochures, flyers, and other marketing materials";
      } else {
        description = "is a cost-effective and user-friendly option for everyday mono printing tasks, ideal for offices, home offices, and businesses that prioritize value and convenience";
      }
      break;

    case "Xerox":
      if (colourPrinting === "full-colour") {
        description = "is a powerful and versatile solution for handling demanding colour printing needs, perfect for production environments and businesses that require high-quality output for a variety of documents";
      } else {
        description = "streamlines workflows and enhances productivity for mono printing tasks, making it a great choice for offices that demand efficiency and reliability";
      }
      break;

    case "Ricoh":
      if (colourPrinting === "full-colour") {
        description = "offers adaptable colour printing capabilities, accommodating a wide range of printing needs, from everyday documents to high-quality marketing materials, making it ideal for businesses with diverse printing requirements";
      } else {
        description = "is a reliable and efficient option for mono printing tasks, suitable for offices, home offices, and businesses that prioritize dependability and cost-effectiveness";
      }
      break;

    case "Toshiba":
      if (colourPrinting === "full-colour") {
        description = "provides economical colour printing with a focus on user-friendliness and convenience, making it a great choice for offices and businesses that value simplicity and affordability";
      } else {
        description = "is a reliable and cost-effective solution for everyday mono printing tasks, ideal for offices, home offices, and businesses that prioritize value and performance";
      }
      break;

    default:
      description = "is a versatile and capable printer that offers high-quality output for a variety of printing needs, suitable for offices, home offices, and businesses of all sizes";
  }

  return description;
}

function getPerfectForScanner({ brand, scanSpeed, resolution }) {
  let description;

  switch (brand) {
    case "Epson":
      if (scanSpeed > 40 && resolution > 1200) {
        description = "is ideal for high-volume scanning environments and businesses that require fast and precise scanning for archival purposes or digitizing large volumes of documents.";
      } else if (scanSpeed > 20 && resolution > 600) {
        description = "is a versatile solution for handling a wide range of scanning tasks, from everyday document scanning to high-quality photo scanning, suitable for offices, home offices, and creative professionals.";
      } else {
        description = "is a cost-effective and user-friendly option for everyday scanning tasks, ideal for offices, home offices, and businesses that prioritize value and convenience.";
      }
      break;

    case "Fujitsu":
      if (scanSpeed > 40) {
        description = "is designed for high-volume scanning environments and businesses that require efficient document management and workflow automation, particularly in industries like healthcare and finance.";
      } else if (scanSpeed > 20) {
        description = "is a reliable and efficient solution for handling moderate scanning volumes, ideal for offices, home offices, and businesses that prioritize productivity and efficiency.";
      } else {
        description = "is a compact and portable scanner that offers high-quality scans for everyday tasks, perfect for mobile professionals, students, and home users.";
      }
      break;

    case "Canon":
      if (scanSpeed > 40 && resolution > 1200) {
        description = "is a powerful and versatile solution for handling demanding scanning needs, perfect for production environments and businesses that require high-quality scans for a variety of purposes, including archival preservation, digital transformation, and legal compliance.";
      } else if (scanSpeed > 20 && resolution > 600) {
        description = "is enhances productivity and efficiency in scanning workflows, ideal for offices with high-volume scanning requirements, making it a great choice for businesses that handle large volumes of paperwork.";
      } else {
        description = "is a versatile option for handling a wide range of scanning tasks, from everyday documents to high-quality photo scanning, suitable for offices, home offices, and creative professionals.";
      }
      break;

    case "Brother":
      if (scanSpeed > 20) {
        description = "is a compact and portable scanner that delivers fast and efficient scanning, ideal for mobile professionals, students, and home users who require quick and easy document digitization.";
      } else {
        description = "is a user-friendly and affordable option for everyday scanning tasks, suitable for offices, home offices, and businesses that prioritize value and convenience.";
      }
      break;

    case "Kodak":
      if (resolution > 1200) {
        description = "is a high-quality scanning solution for businesses that demand exceptional image enhancement and restoration capabilities, ideal for preserving and archiving valuable documents, photographs, and historical materials.";
      } else {
        description = "is a versatile option for handling a wide range of scanning tasks, from everyday documents to high-quality photo scanning, suitable for offices, home offices, and businesses that prioritize image quality and precision.";
      }
      break;

    case "Plustek":
      if (scanSpeed > 40) {
        description = "is an innovative and feature-rich scanner designed for high-volume scanning environments, offering advanced automation features and productivity-enhancing functionalities, making it ideal for businesses that require streamlined workflows and efficient document management.";
      } else if (scanSpeed > 20) {
        description = "is a reliable and efficient solution for handling moderate scanning volumes, ideal for offices, home offices, and businesses that prioritize productivity and efficiency in their scanning operations.";
      } else {
        description = "is a versatile and user-friendly scanner that offers high-quality scans for everyday tasks, suitable for offices, home offices, and businesses that prioritize value, convenience, and ease of use.";
      }
      break;

    case "HP":
      if (scanSpeed > 40) {
        description = "is a versatile scanning solution that offers high-speed scanning, advanced connectivity options, and integration with various workflows, making it ideal for businesses that demand flexibility and seamless integration with their existing systems.";
      } else if (scanSpeed > 20) {
        description = "is a reliable and efficient solution for handling moderate scanning volumes, suitable for offices, home offices, and businesses that prioritize productivity and efficiency in their scanning operations.";
      } else {
        description = "is a user-friendly and affordable option for everyday scanning tasks, suitable for offices, home offices, and businesses that prioritize value and convenience.";
      }
      break;

    default:
      description = "is a versatile and capable scanner that offers high-quality scans for a variety of tasks, suitable for offices, home offices, and businesses of all sizes.";
  }

  if (resolution > 1200) {
    description += " It is particularly well-suited for high-resolution scanning of documents, photos, and other materials that require exceptional image quality.";
  }

  return description;
}

function getPerfectFor(product) {
  if (product.productType === 'scanner') {
    return getPerfectForScanner(product)
  }
  return getPerfectForPrinter(product)
}

// Full colour, mono, high resolution
function applicationType(product) {
  const technology = getValueInArray(product.generalData, 'Technology:')
  if (getProductType(product.type) !== 'scanner') {
    return `, ${product.colourPrinting.replace('-', ' ').toLowerCase()}, ${technology.toLowerCase()} `
  }
  return ``
}

export function getProductLink(item) {
  const { productType, deviceType, brand, model, isProduction } = item
  // Handle multifunctional printers that are production
  if (deviceType === 'multifunctionals' && isProduction === 1) {
    return stringToSlug(`/${productType}s/production/${brand}/${model}`)
  }

  // Product type is pluralised in the url, so extra s there
  return stringToSlug(`/${productType}s/${deviceType}/${brand}/${model}`)
}
// returns the url for product brand logo images
export function getBrandLogo(name) {
  return stringToSlug(`${baseUrl}/images/${name}-logo.png`)
}
// Returns the url for product images
export function getProductImage(item, size = 'small', number = 0) {
  const sizes = {
    thumb: 'x135',
    small: 'x270',
    medium: 'x540',
    large: 'x810'
  }

  // Sometimes model and brand are nested so check for that and move them up if so
  if (typeof item.brand === 'undefined' || typeof item.model === 'undefined') {
    if (typeof item.printers !== 'undefined') {
      Object.keys(item.printers).map(key => {
        item[key] = item.printers[key]
      })
    }
  }

  const urlFragment = `${baseUrl}/${sizes[size]}/${item.brand}/${item.model}`

  if (item.images && item.images.length > 1) {
    return stringToSlug(`${urlFragment}/${item.images[number].filename || item.images[number]}`)

  } else if (item.images && item.images.length > 0) {
    return stringToSlug(`${urlFragment}/${item.images[0].filename || item.images[0]}`)

  } else if (item.filename) {
    return stringToSlug(`${urlFragment}/${item.filename}`)

  }

  return `${baseUrl}/images/no-image.svg`
}
export function getProductPdf(model, brand, type) {
  return `${pdfBaseUrl}/pdfs/${type}/${brand}/${model}.pdf`.replace(RE, '-').toLowerCase()
}
export function getProductMeta(product) {
  const productMeta = {
    title: generateProductTitle(product),
    meta: {
      description: generateProductMetaDescription(product),
      card: 'summary_large_image',
      url: getProductLink(product),
      image: getProductImage(product, 'medium'),
      ogType: 'product'
    }
  }

  return productMeta
}
export function generateProductTitle(product) {
  const printerType =
    product.type === 'multifunctional-printers' ? 'multifunctional printer' : 'printer'
  const colour = product.colourPrinting === 'full-colour' ? 'colour' : 'mono'
  return `${product.brand} ${product.model} ${colour}` + ` ${product.paperSize} ${printerType}`
}
export function generateProductMetaDescription(product) {
  const technology = getValueInArray(product.generalData, 'Technology:')
  const productType = typeDictionary[product.type]

  try {
    const string =
      `${product.brand} ${product.model} ${technology.toLowerCase()}, ` +
      `${productType}. Instant quotes on leasing or purchasing, ` +
      `starting at £${asPounds(
        product.price.startingFrom * 100
      )} or rental from ${asPounds(
        (product.price['60MonthLeasePrice'] * 100) / 3
      )} per month.`
    return string
  } catch (err) {

    return ''
  }
}



// Note on prices, prices in mysql database are decimal datatypes. Retrieved as strings.
// Multiply them by 100 to get integer value and ensure they are numbers.
// Use asPounds to format them as currency.
export function generateProductDescription(product) {

  const dimensions = getValueInArray(product.generalData, 'Dimensions (w x d x h) mm:')
    .replace(/[A-WYZ,]+/ig, '')
  const productType = typeDictionary[product.type]

  const size = getPrinterSize(dimensions, product.type, product.paperSize)

  const strings = [
    `The ${product.brand} ${product.model} is a ${size}${applicationType(product)} ${productType}. It is optimised for use in a ${getCapacitySentence(
      product.capacity
    )}` +
    ` setting. ${getProductOffer(product)} ${speedString(product)}.`,
    // Paragraph break here
    `This ${product.paperSize} ${productType} ${getPerfectFor(
      product
    )}. It is available to lease or buy, with purchase prices starting from ${asPounds(
      product.price.startingFrom * 100
    )} or rental from ${asPounds(
      (product.price['60MonthLeasePrice'] * 100) / 3
    )} per month, excl. VAT`
  ]
  return strings
}
