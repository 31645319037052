<template>
  <div class="md:tw-absolute md:tw-left-0 md:tw-right-0 md:tw-top-full tw-w-screen">
    <div
      class="md:tw-mx-auto md:tw-max-w-3xl tw-bg-white  md:tw-shadow tw-border-t-2 md:tw-border-b-2 tw-border-solid tw-border-zinc-200 md:tw-rounded-b"
    >
      <div class="tw-grid md:tw-grid-cols-3 md:tw-px-6 tw-py-3 tw-gap-2">
        <div
          v-for="section, i in content"
          :key="i"
        >
          <!-- Section heading -->
          <div class="tw-px-6 md:tw-px-2">
            <p class="tw-text-xs is-size-7-desktop md:tw-mb-1 tw-uppercase">
              <strong class="has-text-info">{{ section.heading }}</strong>
            </p>
          </div>
          <!-- Section links -->
          <ul class="md:tw-pb-3">
            <li
              v-for="(link, k) in section.items"
              :key="k"
              class="tw-border-b tw-border-solid md:tw-border-none"
            >
              <DropdownItem
                :key="createKey(`${link.text}-${i}`)"
                :link="link"
                @navigate="handleNavigationClick"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DropdownItem from './NavDropdownItem.vue'
// import NavDropdownSection from './NavDropdownSection.vue'
export default {
  name: 'NavDropdownMenu',
  components: {
    DropdownItem,
    // NavDropdownSection
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  emits: ['toggle-back', 'navigate'],
  methods: {
    createKey(text) {
      return text.replace(/\s/, '-').toLowerCase()
    },
    // Handle navigation. Close dropdown on click
    handleNavigationClick(e) {
      // Template has handle click oin dropdowns, is used in mobile nav but
      // we need a function here to not error out

      // let dropdown
      // let parent
      // if (e.target) {
      //   dropdown = e.target.parentElement
      //   parent = e.target.parentElement.parentElement
      // } else if (e.srcElement) {
      //   dropdown = e.srcElement.parentElement
      //   parent = e.srcElement.parentElement.parentElement
      // }
      this.$emit('toggle-back')
      this.$emit('navigate', e)
      // this.$searchAncestors(
      //   e.target,
      //   'is-active',
      //   (el) => {
      //     el.classList.remove('is-active')
      //   },
      //   'nav-item'
      // )
    },
  }
}
</script>