import { fbAddToCartEvent } from '@@/helpers/util'

// initial state
const state = {
  open: false,
  isSingle: true,
  isInstant: false,
  instant: '',
  basket: {},
  recaptcha: {},
  comparison: []
}

// Keep basket in local store
function getItem(key) {
  // Convert JSON to object when fetching
  if (window) {
    return JSON.parse(window.localStorage.getItem(key))
  }
  return false
}

function saveItem(key, data) {
  // Convert to JSOn before storing
  if (window) {
    window.localStorage.setItem(key, JSON.stringify(data))
  }
}

// function deleteItem (key) {
//   window.localStorage.removeItem(key)
// }

// Pass array of keys to delete
function deleteItems(keys) {
  if (window) {
    keys.forEach(key => {
      window.localStorage.removeItem(key)
    })
  }
}

// // Clear all localstorage
// function clearAll(keys) {
//   if (window) {
//     window.localStorage.clear()
//   }
// }
// actions
const actions = {
  loadBasketFromStorage({ commit }) {
    const storedBasket = getItem('basket')
    if (storedBasket) {
      commit('REPLACE_BASKET', storedBasket)
    }
  },
  addToBasket: ({ commit }, item) => {
    commit('ADD', item)
    fbAddToCartEvent(item)
  },
  // Handles checkout of single / instant product - so it doesnt interfere with
  // operation of comparison / basket
  addToInstant: ({ commit }, item) => {
    commit('ADD_INSTANT', item)
  },
  toggleIsInstant: ({ commit }) => {
    commit('TOGGLE_INSTANT')
  },
  combineInstantCompare: ({ commit }) => {
    commit('COMBINE_INSTANT_COMPARE')
  },
  setInstant: ({ commit }, bool) => {
    commit('TOGGLE_INSTANT', bool)
  },
  toggleIsSingle: ({ commit }) => {
    commit('TOGGLE_SINGLE')
  },
  addAllToBasket: ({ commit }, items) => {
    items.forEach(item => {
      commit('ADD', item)
    })
  },
  removeFromBasket: ({ commit }, modelId) => {
    commit('REMOVE', modelId)
  },
  openBasket: ({ commit }) => {
    commit('SET_BASKET', true)
  },
  closeBasket: ({ commit }) => {
    commit('SET_BASKET', false)
  },
  clearBasket: ({ commit }) => {
    commit('CLEAR')
  },
  checkoutBasket: ({ commit }) => {
    commit('CLEAR')
  },
  setRecaptchaData({ commit }, data) {
    commit('SET_RECAPTCHA_DATA', data)
  }
}

// mutations
const mutations = {
  ADD(state, item) {
    state.basket[item.modelId] = item
    // Store basket state in localstore, just overwrite
    saveItem('basket', state.basket)
  },
  ADD_INSTANT(state, item) {
    state.instant = item.modelId
  },
  REPLACE_BASKET(state, data) {
    state.basket = data
  },
  TOGGLE_INSTANT(state, bool) {
    state.isInstant = bool
  },
  TOGGLE_SINGLE(state) {
    state.isSingle = !state.isSingle
  },
  REMOVE(state, modelId) {
    delete state.basket[modelId]
    // Store basket state in localstore, just overwrite
    saveItem('basket', state.basket)
  },
  CLEAR(state) {
    state.basket = {}
    state.instant = ''
    deleteItems(['basket'])
  },
  TOGGLE_BASKET(state) {
    state.open = !state.open
  },
  SET_BASKET(state, val) {
    state.open = val
  },
  SET_RECAPTCHA_DATA(state, data) {
    state.recaptcha = data
  }
}

// getters
const getters = {
  getBasket: state => state.basket,
  isInstant: state => state.instant !== '',
  isSingle: state => state.isSingle,
  getInstantItem: state => state.instant,
  isBasketOpen: state => state.open,
  recaptchaData: state => state.recaptcha,
  basketCount: state => Object.keys(state.basket).length
}

export default { state, mutations, getters, actions }
