// Tracks just some app state stuff
const state = {
  // track captcha completion status, ensure not needed more than once
  captchaComplete: false,
  // Current service checker step
  currentStep: 0
}

const actions = {
  captchaCompleted: ({ commit }) => {
    commit('UPDATE_CAPTCHA', true)
  }
}

// mutations
const mutations = {
  CHANGE_STEP(state, value) {
    if (typeof value === 'string') {
      state.currentStep = +value
    } else {
      state.currentStep += value
    }
  },
  UPDATE_CAPTCHA(state, value) {
    state.captchaComplete = value
  },
}

// getters
const getters = {
  isHuman: state => state.captchaComplete,
  botStartMessage: state => state.botStartMessage
}

export default { state, mutations, getters, actions }
