<template>
  <nav
    id="siteNavigation"
    class="navbar tw-sticky tw-top-0 has-shadow"
    :class="{ 'open': isNavActive }"
  >
    <div class="container tw-static is-paddingless-touch">
      <div class="navbar-brand tw-items-center">
        <router-link
          class="navbar-item"
          to="/"
          alt="Home"
        >
          <img
            class="image tw-h-12"
            src="https://assets.copytechgroup.co.uk/images/copytech-logo.svg"
            alt="Copytech logo"
          >
        </router-link>

        <basket-toggle
          class="is-hidden-desktop"
          :is-active="isDrawerActive"
          @toggle-basket="toggleMenu('drawer')"
        />

        <div
          id="nav-toggle"
          class="navbar-burger"
          :class="{ 'is-active': isNavActive }"
          @click="toggleMenu('nav')"
        >
          <span /><span /><span />
        </div>
      </div>

      <div
        class="navbar-menu tw-top-18"
        :class="{ 'is-active': isNavActive }"
      >
        <div class="navbar-end">
          <navigation @navigate="navigate('nav')" />
        </div>
        <basket-toggle
          class="is-hidden-touch"
          :is-active="isDrawerActive"
          @toggle-basket="toggleMenu('drawer')"
        />
      </div>

      <transition name="fade">
        <div
          v-show="isDrawerActive"
          class="overlay is-hidden-touch"
        />
      </transition>

      <div
        class="navbar-drawer is-desktop match-navbar is-right"
        :class="{ 'is-active': isDrawerActive }"
      >
        <div
          class="navbar-basket is-hidden-touch has-dropdown has-items-right"
          title="Close basket"
          @click="toggleMenu('drawer')"
        >
          <div class="basket-toggler tw-h-full">
            <span class="icon is-large transition-in-place">
              <div class="fa-stack fa-fw"><i class="fal fa-times" /></div>
            </span>
          </div>
        </div>
        <component
          :is="quoteComponent"
          @close="toggleMenu('drawer')"
        />
      </div>
    </div>
  </nav>
</template>

<script>
import Navigation from './navigation/Navigation.vue'
import BasketToggle from './BasketToggle.vue'
import Basket from './Basket.vue'
import QuoteDetails from './forms/Details.vue'
import Confirmation from './Confirmation.vue'
import SlideMenu from './SlideMenu.vue'

export default {
  name: 'NavBar',
  components: {
    Navigation,
    Basket,
    BasketToggle,
    QuoteDetails,
    Confirmation,
    SlideMenu
  },
  data() {
    return {
      isNavActive: false,
      isDrawerActive: false
    }
  },
  computed: {
    component() {
      return this.$store.getters.getModalComponent
    },
    quoteComponent() {
      if (
        this.component === 'QuoteDetails' ||
        this.component === 'Confirmation'
      ) {
        return this.component
      } else {
        return 'basket'
      }
    },
    basketOpen() {
      return this.$store.getters.isBasketOpen
    }
  },
  watch: {
    basketOpen() {
      this.toggleMenu('drawer')
    }
  },
  methods: {
    toggleScrolling() {
      const scrollClass = 'u-no-scroll'
      const el = document.documentElement

      if (el.classList.contains(scrollClass)) {
        el.classList.remove(scrollClass)
      } else {
        el.classList.add(scrollClass)
      }
    },
    toggleOverlay() {
      this.$emit('drawer-toggle')
    },
    escListener(menu, e) {
      const key = e.key

      if (key === 'Escape') {
        this.toggleMenu(menu)
      }
    },
    toggleEscListener(menu) {
      document.addEventListener('keydown', this.escListener.bind(null, menu), {
        once: true
      })
    },
    toggleMenu(menu) {
      if (window.matchMedia('(max-width: 1007px)').matches) {
        // If this is toggling nav inactive, ensure show back is false
        if (!this.isNavActive && !this.isDrawerActive) {

          if (menu === 'nav') {
            this.isNavActive = true
            this.toggleEscListener(menu)

          } else if (menu === 'drawer') {
            this.isDrawerActive = true
            this.toggleEscListener(menu)
          }
          // Also remove scrolling class from document
          this.toggleScrolling()
        } else {
          if (menu === 'nav') {
            this.isNavActive = false
          } else if (menu === 'drawer') {
            this.isDrawerActive = false
            this.$store.dispatch('closeBasket')
          }

          // Activating nav
          this.toggleScrolling()

          // FXOno longer exists
          // try {
          //   this.fxoWidget.style.visibility = 'visible'
          // } catch (e) {
          // } // We dont care if it fails
        }
      } else {
        if (!this.isNavActive && !this.isDrawerActive) {
          if (menu === 'nav') {
            // console.log('weeee')
            // Navigating from dropdown menu normally
            // this.toggleScrolling()
          } else if (menu === 'drawer') {
            this.isDrawerActive = true
            this.toggleEscListener(menu)
            // Activating nav
            this.toggleScrolling()
          }
        } else {
          if (menu === 'nav') {
            // this.isNavActive = false;
          } else if (menu === 'drawer') {
            this.isDrawerActive = false
            this.toggleScrolling()
            this.$store.dispatch('closeBasket')
          }
        }
      }
    },
    navigate(menu) {
      this.toggleMenu(menu)
    }
  },
  // mounted() {
  //   let lastScroll = 0
  //   const spacerEl = document.querySelector('.global-nav')
  //   const self = this.$el
  //   const { height } = spacerEl.getBoundingClientRect()
  //   let lastTop = 0
  //   // Pass 1 or -1 to increase/decrease
  //   function updateTop(el, val) {
  //     el.style.top = val + 'px'
  //     lastTop = val
  //   }

  //   // Start listening for scroll events,
  //   document.addEventListener('scroll', e => {
  //     lastScroll = window.scrollY
  //     const difference = height - lastScroll
  //     if (difference >= 0) {
  //       window.requestAnimationFrame(() => updateTop(self, difference))
  //     } else if (difference < 0) {
  //       window.requestAnimationFrame(() => updateTop(self, 0))
  //     }
  //   })
  // }
}
</script>
