// title-mixin.js
// Hardcode template for now
import { useSSRContext } from 'vue'
const titleTemplate = ' - Copytech'

/**
 * Get title
 */
function getTitle(vm) {
  // components can simply provide a `title` option
  // which can be either a string or a function
  const { title } = vm.$options

  if (title) {
    return typeof title === 'function'
      ? title.call(vm)
      : title
  }
}

const serverTitleMixin = {
  created() {
    if (import.meta.env.SSR) {
      const ssrContext = useSSRContext()
      const title = getTitle(this)
      if (title) {
        if (ssrContext) {
          ssrContext.title = title + titleTemplate
        }
      }
    }

  }
}

const clientTitleMixin = {
  mounted() {
    const title = getTitle(this)
    if (title) {
      document.title = title + titleTemplate
    }
  }
}

// /**
//  * Get description
//  */
// function getDescription(vm) {
//   // components can simply provide a `description` option
//   // which can be either a string or a function
//   const { description } = vm.$options
//   if (description) {
//     return typeof description === 'function'
//       ? description.call(vm)
//       : description
//   }
// }

// const serverDescriptionMixin = {
//   created() {
//     const description = getDescription(this)
//     if (description) {
//       this.$ssrContext.description = description
//     }
//   }
// }

// const clientDescriptionMixin = {
//   mounted() {
//     const description = getDescription(this)
//     if (description) {
//       document.description = description
//     }
//   }
// }

/* disable-eslint */
// `VUE_ENV` can be injected with `webpack.DefinePlugin`
export default (import.meta.env.SSR ? serverTitleMixin : clientTitleMixin)
