<template>
  <div class="modal-gallery">
    <ul class="modal-gallery-list">
      <li
        v-for="image, index in images"
        :key="index"
        class="modal-gallery__item"
        :class="`item-${index}`"
      >
        <img
          class="modal-gallery-item__image"
          :src="image.src"
        >
      </li>
    </ul><span
      v-if="images.length &gt; 1"
      class="modal-gallery__modal__controls modal-gallery__modal__controls_left has-absolute-center"
      @click="throttledClick('previous')"
    ><span class="icon is-medium"><i class="fal fa-lg fa-chevron-left" /></span></span><span
      v-if="images.length &gt; 1"
      class="modal-gallery__modal__controls modal-gallery__modal__controls_right has-absolute-center"
      @click="throttledClick('next')"
    ><span class="icon is-medium"><i class="fal fa-lg fa-chevron-right" /></span></span>
  </div>
</template>
<script>
import { throttle } from 'lodash-es'
import { preLoadImages } from '../helpers/element'

export default {
  props: ['product'],
  data() {
    return {
      imageIndex: 0,
      images: []
    }
  },
  computed: {
    prevImage() {
      const i = this.imageIndex - 1 >= 0 ? this.imageIndex - 1 : this.images.length - 1
      return this.images[i].src
    },
    currentImage() {
      return this.images[this.imageIndex].src
    },
    lastImage() {
      return this.images.length - 1
    },
    nextImage() {
      const i = this.imageIndex + 1 === this.images.length ? 0 : this.imageIndex + 1
      return this.images[i].src
    }
  },
  methods: {
    cleanUp(e) {
      let el
      if (e.target) {
        el = e.target
      } else if (e.srcElement) {
        el = e.srcElement
      }

      if (el.classList.contains('leaveLeft')) {
        el.classList.remove('leaveLeft')
      } else {
        el.classList.remove('leaveRight')
      }
    },
    changeEnter(prev, next) {
      // remove old classes
      this.$el.querySelector('.prevImage').classList.remove('prevImage')
      this.$el.querySelector('.nextImage').classList.remove('nextImage')
      // Add new classes
      this.$el.querySelector(`.item-${prev}`).classList.add('prevImage')
      this.$el.querySelector(`.item-${next}`).classList.add('nextImage')
    },
    changeLeave(el, direction) {
      // Clean up after transition
      el.addEventListener('transitionend', this.cleanUp)
      if (direction === 'left') {
        el.classList.add('leaveLeft')
      } else {
        el.classList.add('leaveRight')
      }
    },
    changeImage(to, from) {
      const currentItem = this.$el.querySelector(`.item-${from}`)
      let nextItem = this.$el.querySelector(`.item-${to}`)
      let direction
      // If from is larger than to, movement direction is right
      if (from > to) { direction = 'right' }
      if (from < to) { direction = 'left' }

      // If previous is lower than 0, set it to last image
      let prevIndex = to - 1 === -1 ? this.lastImage : to - 1
      // If next is higher than arr length, set it to first element
      let nextIndex = to + 1 > this.lastImage ? 0 : to + 1

      const t = to + 1
      const f = from + 1

      // If from is 1 and next is length of image array(+1), we are looping back
      if (f === 1 && t === this.images.length) {
        direction = 'right'
        nextItem = this.$el.querySelector(`.item-${this.lastImage}`)
        // Previous is the second to alst item of the array, if there is one
        prevIndex = Math.max(this.images.length - 2, 0)
        // Next item is the first element
        nextIndex = 0
      }
      // If from is the length of array and next is 1, we are looping to start of array
      if (f === this.images.length && t === 1) {
        direction = 'left'
        nextItem = this.$el.querySelector(`.item-0`)
        // Previous is last item, if arr is long enough
        prevIndex = this.lastImage
        // next is second item, if there is one
        nextIndex = Math.min(1, this.lastImage)
      }

      this.changeLeave(currentItem, direction)
      this.changeEnter(prevIndex, nextIndex)
      // Deactivate current item
      currentItem.classList.remove('active')

      // // Activate next item
      nextItem.classList.add('active')
    },
    previous() {
      // this.changeClasses('left')
      if (this.imageIndex - 1 < 0) {
        this.imageIndex = this.lastImage
      } else {
        this.imageIndex--
      }
    },
    next() {
      // this.changeClasses('right')
      if (this.imageIndex + 1 === this.images.length) {
        this.imageIndex = 0
      } else {
        this.imageIndex++
      }
    },
    throttledClick: throttle(function (direction) {
      if (direction === 'previous') {
        this.previous()
      } else {
        this.next()
      }
    }, 300),
    handleKeys(e) {
      if (e.keyCode === 37) {
        this.previous()
      }
      if (e.keyCode === 39) {
        this.next()
      }
    }
  },
  watch: {
    imageIndex: 'changeImage'
  },
  beforeMount() {
    // Preload images
    this.images = preLoadImages(
      this.product.images,
      this.product.brand,
      this.product.model,
      'large'
    )

    // Add listener for keyboad events
    window.addEventListener('keyup', this.handleKeys)
  },
  mounted() {
    // Add initial class
    setTimeout(() => {
      const el = this.$el
      // Initial element
      el.querySelector('.item-0').classList.add('active')
      // Only set other images if this product actuall has more than one image
      if (this.images.length > 1) {
        // Initial left element
        el.querySelector(`.item-${this.lastImage}`).classList.add('prevImage')
        // Initial right element
        el.querySelector('.item-1').classList.add('nextImage')
      }
    }, 300)
  },
  beforeUnmount() {
    // remove listener
    window.removeEventListener('keyup', this.handleKeys)
  }
}
</script>
